/* eslint-disable eqeqeq */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, IconButton } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from '@mui/material/Typography';
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { AllCampaignsActions } from "../../../../actions/allcampaign.actions";
import { clientActions } from "../../../../actions/client.actions";
import { desmembramentoActions } from "../../../../actions/desmembramento.actions";
import { nivelSemaforoActions } from "../../../../actions/nivelSemaforo.actions";
import { productActions } from "../../../../actions/products.actions";
import { simulator2Actions } from '../../../../actions/simulator2.actions';
import ResumeCp from './components/Cp/Resume';
import { zeroPad } from "./components/HelpersComponents";
import { TableProdutos } from "./components/SelecaoProduto";
import { classUseRedirect, isNullOrEmpty } from '../../../../helpers';
import { metodoPagamentoActions } from '../../../../actions/metodopagamento.actions';
import { TableClientesView } from './components/DesmembramentoClienteView';
import { InfoDesmembramentoTable } from "./components/DesmembramentoResumeView";
import { formatDate } from '../../../../helpers/utils';

function Form({ cenario = {}, formulario = {}, moeda = "USD", allMetodos = [], actions = {}, isLoadingDownload = false, situacaoEspecial = [], municipios = [], metodoPagamentoCpItem, dmbRequisition }) {
  const [open, setOpen] = React.useState(true);

  return (
    <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }}>
      <InfoDesmembramentoTable requisition={dmbRequisition} />

      {cenario.isCpActive && cenario?.cenarioV2Cp && formulario.formularioCp &&
        <>
          <Grid item xs={2} sm={8} md={12} >
            <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "18px", color: "white", backgroundColor: "var(--cort-blue)", fontWeight: "bold" }}>
              <Grid item xs={12} sx={{ paddingTop: "1rem !important" }} >
                <p>CROP PROTECTION</p>
              </Grid>
            </Grid>
          </Grid>

          <ResumeCp cenario={cenario?.cenarioV2Cp} metodoPagamento={metodoPagamentoCpItem} />

          {/*FORMULÁRIO ----- INICIO*/}

          <Grid item xs={2} sm={8} md={6} className="align-self-center">
            <h5>
              <b>Order Type:</b>
            </h5>
            <h5>
              {situacaoEspecial.filter(x => x.business === "Crop").find(x => x.value == formulario.formularioCp.orderType).name}
            </h5>
          </Grid>
          <Grid item xs={2} sm={8} md={6} className="align-self-center">
            <h5>
              <b>PO Number:</b>
            </h5>
            <h5>
              {formulario.formularioCp.poNumber}
            </h5>
          </Grid>
          <Grid item xs={2} sm={8} md={12} className="align-self-center">
            <h5>
              <b>Instruções CSR: </b>
            </h5>
            <h5>
              {formulario.formularioCp.instrucoesCsr}
            </h5>
          </Grid>

          {/*FORMULÁRIO ----- FIM*/}

          {/*TABELAS ----- INICIO*/}
          <Grid item xs={2} sm={8} md={12} >
            <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "18px", color: "white", backgroundColor: "var(--cort-blue)", fontWeight: "bold" }}>
              <Grid item xs={12} sx={{ paddingTop: "calc(1rem - 8px) !important" }} >
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon color="whiteB" /> : <KeyboardArrowDownIcon color="whiteB" />}
                </IconButton>
                <p style={{ display: "inline-flex", marginLeft: "8px" }}>Produtos do cenário</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={8} md={12} >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <TableProdutos
                rows={cenario?.cenarioV2Cp?.itensCenarios}
                currency={moeda}
              />
            </Collapse>
          </Grid>
          <Grid item xs={2} sm={8} md={12} >
            <TableClientesView
              formulario={formulario?.formularioCp}
              rows={formulario?.formularioCp?.cenarioFormularioAccount}
              itens={cenario?.cenarioV2Cp?.itensCenarios}
              currency={moeda}
              situacaoEspecial={situacaoEspecial}
            />
          </Grid>
          {/*TABELAS ----- FIM*/}
        </>
      }

      {cenario.isSeedsActive && !isNullOrEmpty(cenario?.cenarioV2Seeds) && !isNullOrEmpty(formulario.formularioSeeds) &&
        <>
          <Grid item xs={2} sm={8} md={12} >
            <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", color: "white", backgroundColor: "var(--cort-blue)", fontWeight: "bold" }}>
              <Grid item xs={12} sx={{ paddingTop: "1rem !important" }} >
                <p>SEEDS</p>
              </Grid>
            </Grid>
          </Grid>

          {formulario.formularioSeeds.map((x, i) => {
            const cenarioSeed = cenario?.cenarioV2Seeds.find(c => c.cultura == x.cultura)
            const metodoPagamentoSeedsItem = allMetodos.find(metodo => metodo.metodoPagamentoId === cenarioSeed.condicao);

            return (
              <React.Fragment key={cenarioSeed.id}>
                <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: 2, width: "calc(100%) !important", marginTop: "18px", backgroundColor: "#e6e8ea", fontWeight: "bold" }}>
                  <Grid item xs={12} sx={{ paddingTop: "1rem !important" }}>
                    <p>{x.cultura}</p>
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={8} md={2.5}>
                  <label className="d-block">Campanha</label>
                  <h5>{cenarioSeed.campanhaGroup}</h5>
                </Grid>
                <Grid item xs={2} sm={8} md={2.5}>
                  <label className="d-block">Condição</label>
                  <h5>{metodoPagamentoSeedsItem?.metodo}</h5>
                </Grid>
                {metodoPagamentoSeedsItem?.isBarter && (
                  <Grid item xs={2} sm={8} md={2.5}>
                    <label className="d-block">Valorização Barter</label>
                    <h5>{cenarioSeed.valorizacaoBarter || ''}</h5>
                  </Grid>
                )}
                {metodoPagamentoSeedsItem?.isBonusAdimplencia && (
                  <Grid item xs={2} sm={8} md={2.5}>
                    <label className="d-block">Bônus Adimplencia</label>
                    <h5>{cenarioSeed.bonusAdimplencia || ''}</h5>
                  </Grid>
                )}
                <Grid item xs={2} sm={8} md={2.5}>
                  <label className="d-block">Vencimento</label>
                  <h5>{cenarioSeed.vencimento}</h5>
                </Grid>
                {/*FORMULÁRIO ----- INICIO*/}
                <Grid item xs={2} sm={8} md={6} className="align-self-center">
                  <h5>
                    <b>Order Type:</b>
                  </h5>
                  <h5>
                    {situacaoEspecial.filter(x => x.business === "Seed").find(y => y.value == x.orderType).name}
                  </h5>
                </Grid>
                <Grid item xs={2} sm={8} md={6} className="align-self-center">
                  <h5>
                    <b>PO Number:</b>
                  </h5>
                  <h5>
                    {x.poNumber}
                  </h5>
                </Grid>
                <Grid item xs={2} sm={8} md={12} className="align-self-center">
                  <h5>
                    <b>Instruções CSR: </b>
                  </h5>
                  <h5>
                    {x.instrucoesCsr}
                  </h5>
                </Grid>
                {/*FORMULÁRIO ----- FIM*/}

                {/*TABELAS ----- INICIO*/}
                <Grid item xs={2} sm={8} md={12} >
                  <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "18px", color: "white", backgroundColor: "var(--cort-blue)", fontWeight: "bold" }}>
                    <Grid item xs={12} sx={{ paddingTop: "calc(1rem - 8px) !important" }} >
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                      >
                        {open ? <KeyboardArrowUpIcon color="whiteB" /> : <KeyboardArrowDownIcon color="whiteB" />}
                      </IconButton>
                      <p style={{ display: "inline-flex", marginLeft: "8px" }}>Produtos do cenário</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={8} md={12} >
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <TableProdutos
                      rows={cenarioSeed.rows}
                      currency={cenarioSeed.moeda}
                      isSeeds={true}
                    />
                  </Collapse>
                </Grid>
                <Grid item xs={2} sm={8} md={12} >
                  <TableClientesView
                    rows={x.cenarioFormularioAccount}
                    itens={cenarioSeed.rows}
                    isSeeds={true}
                    metodoPagamento={metodoPagamentoSeedsItem?.metodo}
                    currency={moeda}
                    situacaoEspecial={situacaoEspecial}
                    formulario={formulario?.formularioSeeds[i]}
                  />
                </Grid>
                {/*TABELAS ----- FIM*/}
              </React.Fragment>
            )
          })}

        </>
      }
    </Grid >
  );
}

class DesmembramentoPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientId: "",
      cenarioId: 0
    };
  }

  componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.setState({ clientId: params.GroupId });
    this.setState({ cenarioId: params.CenarioId });

    this.props.actions.GetCenario(params.CenarioId);
    this.props.actions.requestFormulario(params.CenarioId);
    this.props.actions.requestClient(params.GroupId);
    this.props.actions.requestDesmembermentInfos(params.CenarioId);
    this.props.actions.requestOrderTypes();
    this.props.actions.requestAllMetodos();
  }

  render() {
    const { client } = this.props.simulator2.clientes;
    const cenario = this.props.dmb.cenario;
    const moeda = cenario?.moeda ?? "USD";

    const newerInfos = !isNullOrEmpty(this.props.dmb.dmbInfos) ? this.props.dmb.dmbInfos?.sort((a, b) => new Date(a.ultimaAtualizacao) - new Date(b.ultimaAtualizacao))[0] : [];

    return (
      <div className="card">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.dmb.cenarioStatus === "loading" || this.props.simulator2.clientes.isLoadingClient || this.props.metodoPagamento.isLoading || this.props.dmb.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={`/Simulador2/MinhasSimulacoesCliente?GroupId=${this.state.clientId}`}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/"}>
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={"/Simulador2"}
              >
                Simulador 2.0
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={`/Simulador2/MinhasSimulacoesCliente?GroupId=${this.state.clientId}`}
              >
                Minhas Simulações
              </Link>
              <Typography color="text.primary">Desmembramento</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={8} md={12}>
              <Stack
                justifyContent="flex-start"
                alignItems="start"
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                sx={{
                  [`& h5`]: {
                    fontWeight: "bold",
                    marginBottom: 0
                  },
                  mb: "18px"
                }}
              >
                <h5>Simulação #{zeroPad(cenario.id, 4)}</h5>
                <h5>Distrito: {client.distrito}</h5>
                <h5>Território: {client.territorio}</h5>
              </Stack>
            </Grid>
            <Grid item xs={2} sm={4} md={2.4} >
              <h5>
                <b>Cliente: </b>
              </h5>
            </Grid>
            <Grid item xs={2} sm={4} md={3.6}>
              <h5>
                {client.nomeVisaoUnica}
              </h5>
            </Grid>
            <Grid item xs={2} sm={4} md={2.4}>
              <h5>
                <b>Valor total Simulação: </b>
              </h5>
            </Grid>
            <Grid item xs={2} sm={4} md={3.6}>
              <h5>
                {cenario.totalSimulacao?.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: moeda === "BRL" ? "BRL" : "USD",
                })}
              </h5>
            </Grid>

            <Grid item xs={2} sm={4} md={2.4}>
              <h5>
                <b>Situação do Cenário: </b>
              </h5>
            </Grid>
            <Grid item xs={2} sm={4} md={3.6}>
              <h5>
                {newerInfos?.situacao}
              </h5>
            </Grid>

            <Grid item xs={2} sm={4} md={2.4}>
              <h5>
                <b>Última Atualização: </b>
              </h5>
            </Grid>
            <Grid item xs={2} sm={4} md={3.6}>
              <h5>
                {formatDate(newerInfos?.ultimaAtualizacao)}
              </h5>
            </Grid>

          </Grid>

          {!this.props.dmb.isLoading &&
            <Form
              actions={this.props.actions}
              moeda={moeda}
              cenario={cenario}
              isLoadingDownload={this.props.formulario.isLoadingDownload}
              formulario={this.props.dmb.formulario}
              situacaoEspecial={this.props.dmb.situacaoEspecial}
              municipios={this.props.clientes.municipios}
              sendFormulario={this.sendFormulario}
              metodoPagamentoCpItem={this.props.metodoPagamento.metodo}
              allMetodos={this.props.metodoPagamento.allMetodos}
              saveFormulario={this.saveFormulario}
              dmbRequisition={this.props.dmb.dmbInfos}
            />
          }
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      formulario: state.formulario,
      dmb: state.dmb,
      clientes: state.clientes,
      simulator2: state.simulator2,
      metodoPagamento: state.metodoPagamento
    }
  },
  (dispatch) => {
    return {
      actions: bindActionCreators(
        {
          ...productActions,
          ...simulator2Actions,
          ...clientActions,
          ...AllCampaignsActions,
          ...nivelSemaforoActions,
          ...metodoPagamentoActions,
          ...desmembramentoActions
        },
        dispatch,
      ),
    };
  }
)(classUseRedirect(DesmembramentoPage));
