import React from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'
import { ThemeProvider } from '@mui/material/styles';
import { theme } from "./HelpersComponents";
import { GetSafeNumber } from "../../../../../helpers";

export function TableProdutos({ rows, currency, isSeeds = false }) {
  const [rowHeight, setRowHeight] = React.useState(0);

  const rowRef = React.useCallback(node => {
    if (node !== null) {
      [...node.childNodes].forEach(({ offsetHeight = 0 }) => {
        if (rowHeight < offsetHeight) {
          setRowHeight(() => offsetHeight)
        }
        node.style.height = `${rowHeight}px`;
      })
    }
  }, [rowHeight]);

  return (
    <TableContainer component={Paper}>
      <ThemeProvider theme={theme}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="custom pagination table">
          <TableHead>
            <TableRow sx={{
              "th": {
                fontWeight: "bold",
                whiteSpace: 'nowrap'
              },
            }}>
              <TableCell>Produto</TableCell>
              {isSeeds && <TableCell>Sufixo</TableCell>}
              <TableCell align="right">Volume</TableCell>
              <TableCell align="right">Preço Negociado</TableCell>
              <TableCell align="right">Valor Final</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map(row => {
              return (
                <TableRow key={row.id} ref={rowRef}>
                  <TableCell >
                    {row?.produto}
                  </TableCell>
                  {isSeeds &&
                    <TableCell >
                      {row?.sufixo}
                    </TableCell>
                  }
                  <TableCell align="right">
                    {GetSafeNumber(row?.volume).toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell align="right">
                    {
                      GetSafeNumber(row?.precoNegociado, 2).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>
                  <TableCell align="right">
                    {
                      GetSafeNumber(isSeeds ? row?.valorFinalNegociado : row?.valorFinal).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: (currency === "BRL" ? "BRL" : "USD"),
                      })
                    }
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </ThemeProvider>
    </TableContainer>
  );
}