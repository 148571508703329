import { HandleResponse, isNullOrEmpty } from "../helpers";
import { metodoPagamentoActions } from "./metodopagamento.actions";

const requestFormularioType = "REQUEST_FORMULARIOS";
const receiveFomularioType = "RECEIVE_FORMULARIOS";

const requestOrderType = "DESMEMBRAMENTO_REQUEST_ORDER_TYPE";
const receiveOrderType = "DESMEMBRAMENTO_RECEIVE_ORDER_TYPE";

const requestGetCenarioType = "DESMEMBRAMENTO_REQUEST_GET_CENARIO";
const receiveGetCenarioType = "DESMEMBRAMENTO_RECEIVE_GET_CENARIO";
const failureGetCenarioType = "DESMEMBRAMENTO_FAILURE_GET_CENARIO";

const requestPostFormularioType = "DESMEMBRAMENTO_REQUEST_POST_FORMULARIO";
const receivePostFormularioType = "DESMEMBRAMENTO_RECEIVE_POST_FORMULARIO";
const failurePostFormularioType = "DESMEMBRAMENTO_FAILURE_POST_FORMULARIO";

const requestDmbInfos = "DESMEMBRAMENTO_REQUEST_INFOS";
const receiveDmbInfos = "DESMEMBRAMENTO_RECEIVE_INFOS";

const requestSendDmb = "FORM_DESMEMBRAMENTO_REQUEST";
const receiveSendDmb = "FORM_DESMEMBRAMENTO_RECEIVE";
const failureSendDmb = "FORM_DESMEMBRAMENTO_FAILURE";

const clearFormDmb = "LIMPAR_DESMEMBRAMENTO_FORM";

const initialState = {
  formulario: null,
  isLoading: false,
  sendSuccess: false,
  situacaoEspecial: [],
  isLoadingSituacaoEspecial: false,
  cenario: {},
  cenarioStatus: "idle",
  dmbInfos: undefined,
};

export const desmembramentoActions = {
  GetCenario: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestGetCenarioType });

    const url = `/api/Desmembramento/Cenario?id=${id}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    HandleResponse(response).then(
      (cenario) => {
        dispatch({ type: receiveGetCenarioType, cenario });
        if (cenario.isCpActive ?? true) {
          if (!isNullOrEmpty(cenario.cenarioV2Cp?.metodoPagamento)) {
            dispatch(
              metodoPagamentoActions.GetMetodoPagamento(
                cenario.cenarioV2Cp.metodoPagamento
              )
            );
          }
        }
      },
      (error) => {
        dispatch({ type: failureGetCenarioType });
        dispatch({
          type: "CALL_ALERT",
          title: "Não foi possível encontrar o cenário.",
          message: error,
          responseType: "error",
        });
      }
    );
  },
  PostFormulario:
    (id, formulario, status = 0) =>
    async (dispatch, getState) => {
      const { oidc } = getState();

      dispatch({ type: requestPostFormularioType });

      const url = `/api/Desmembramento/Fomulario?status=${status}`;

      const options = {
        method: "POST",
        body: JSON.stringify(formulario),
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${oidc.user.access_token}`,
          "Content-Type": "application/json",
        },
      };

      const response = await fetch(url, options);

      HandleResponse(response).then(
        (cenario) => {
          dispatch({ type: receivePostFormularioType });
          dispatch(desmembramentoActions.requestFormulario(id));
        },
        (error) => {
          dispatch({ type: failurePostFormularioType });
          dispatch({
            type: "CALL_ALERT",
            title: "Não foi possível salvar o formulário.",
            message: error,
            responseType: "error",
          });
        }
      );
    },
  requestFormulario: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestFormularioType });

    const url = `/api/Desmembramento/Fomulario?cenarioId=${id}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const formulario = await response.json();
    dispatch({ type: receiveFomularioType, formulario });
  },
  requestOrderTypes: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestOrderType });

    const url = `/api/Desmembramento/orderType`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const situacaoEspecial = await response.json();
    dispatch({ type: receiveOrderType, situacaoEspecial });
  },
  requestDesmembermentInfos: (id) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestDmbInfos });

    const url = `/api/Desmembramento/FormularioStatus?Id=${id}`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const infos = await response.json();
    dispatch({ type: receiveDmbInfos, infos });
  },
  saveFormulario: (formulario, htmlPage) => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestSendDmb });

    const url = `/api/Desmembramento/pushBuyRequest`;
    const options = {
      method: "POST",
      body: JSON.stringify({ formulario, htmlPage }),
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
        "Content-Type": "application/json",
      },
    };

    const response = await fetch(url, options);
    HandleResponse(response).then(
      (_) => {
        dispatch({ type: receiveSendDmb });
      },
      (error) => {
        dispatch({ type: failureSendDmb });
        dispatch({
          type: "CALL_ALERT",
          title: "Não foi possível enviar o formulário.",
          message: error,
          responseType: "error",
        });
      }
    );
  },
  clearFormDmb: () => (dispatch) => {
    dispatch({ type: clearFormDmb });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case requestFormularioType:
      return {
        ...state,
        isLoading: true,
      };
    case receiveFomularioType:
      return {
        ...state,
        formulario: action.formulario,
        isLoading: false,
      };
    case requestOrderType:
      return {
        ...state,
        isLoadingSituacaoEspecial: true,
      };
    case receiveOrderType:
      return {
        ...state,
        isLoadingSituacaoEspecial: false,
        situacaoEspecial: action.situacaoEspecial,
      };
    case requestGetCenarioType:
      return {
        ...state,
        cenarioStatus: "loading",
        cenario: {},
      };
    case receiveGetCenarioType:
      return {
        ...state,
        cenarioStatus: "idle",
        cenario: action.cenario,
      };
    case failureGetCenarioType:
      return {
        ...state,
        cenarioStatus: "failure",
        cenario: {},
      };
    case requestPostFormularioType:
      return {
        ...state,
        isLoading: true,
      };
    case receivePostFormularioType:
      return {
        ...state,
        isLoading: false,
        sendSuccess: true,
      };
    case failurePostFormularioType:
      return {
        ...state,
        isLoading: false,
      };
    case requestSendDmb:
      return {
        ...state,
        isLoading: true,
        sendSuccess: false,
      };
    case receiveSendDmb:
      return {
        ...state,
        isLoading: false,
        sendSuccess: true,
        formulario: {
          ...state.formulario,
          formularioStatus: 2
        }
      };
    case clearFormDmb:
      return {
        ...state,
        isLoading: false,
        sendSuccess: false,
        cenarioStatus: "idle",
        cenario: {},
        formulario: {},
      };
    case receiveDmbInfos:
      return {
        ...state,
        dmbInfos: action.infos,
      };
    default:
      return state;
  }
};
