import React from 'react';
import Grid from "@mui/material/Grid";
import { TextField, Typography } from '@mui/material';
import { ErrorTooltip } from './ErrorTooltip';

export default function ResumoResultadosCp(props) {
  const { campaign, isEdit = false, cliente, sumFinalPrice, encargos, qtdPremium, maxDscOfertaEstruturada, dscOfertaEstruturada, changeDscOferta } = props;
  const mdSize = isEdit ? 1.7 : 2;

  return (
    <>
      {campaign?.hasDscOfertaEstruturada === false ?
        <>
          <Grid item xs={12} sm={4} md={mdSize} sx={{ textAlign: "center" }}>
            <Typography variant='body2'>Encargos % (+)</Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={mdSize} sx={{ textAlign: "center" }}>
            <Typography variant='body1' sx={{ fontWeight: "bold" }}> {encargos}% </Typography>
          </Grid>
        </>
        :
        (<>
          <Grid item xs={12} sm={4} md={mdSize} sx={{ marginTop: '40px', marginBottom: '40px', textAlign: "center" }}>
            <Typography variant='body2' gutterBottom>Área Soja</Typography>
            <Typography variant='body1' mt={1} sx={{ fontWeight: "bold" }}>
              {Number(cliente?.areaPlantadaSoja).toLocaleString("pt-BR")} Ha
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={mdSize} sx={{ marginTop: '40px', marginBottom: '40px', textAlign: "center" }}>
            <Typography variant='body2' gutterBottom>Potencial</Typography>
            <Typography variant='body1' mt={1} sx={{ fontWeight: "bold" }}>
              {(Number(cliente.sojaPotencialCpUsd ?? 0)).toLocaleString("pt-BR", {
                style: "currency",
                currency: "USD",
              })}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={mdSize} sx={{ marginTop: '40px', marginBottom: '40px', textAlign: "center" }}>
            <Typography variant='body2' gutterBottom>SoC Crop:</Typography>
            <Typography variant='body1' mt={1} sx={{ fontWeight: "bold" }}>{(sumFinalPrice).toFixed(2)} %</Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={mdSize} sx={{ marginTop: '40px', marginBottom: '40px', textAlign: "center" }}>
            <Typography variant='body2' gutterBottom>#Produtos Premium:</Typography>
            <Typography variant='body1' mt={1} sx={{ fontWeight: "bold" }}>{qtdPremium}</Typography>
          </Grid>
          {!isEdit ?
            <Grid item xs={12} sm={4} md={2} sx={{ marginTop: '40px', marginBottom: '40px', textAlign: "center" }}>
              <Typography variant='body2' gutterBottom>Desconto Oferta Estruturada %</Typography>
              <Typography variant='body1' mt={1} sx={{ fontWeight: "bold" }}>{`-${(dscOfertaEstruturada).toFixed(1)}%`}</Typography>
            </Grid>
            :
            <>
              <Grid item xs={12} sm={4} md={mdSize} sx={{ marginTop: '40px', marginBottom: '40px', textAlign: "center" }}>
                <Typography variant='body2' gutterBottom>Dsc. Máximo Oferta %</Typography>
                <Typography variant='body1' mt={1} sx={{ fontWeight: "bold" }}>{`-${(maxDscOfertaEstruturada).toFixed(1)}%`}</Typography>
              </Grid>
              <Grid item xs={12} sm={4} md={mdSize} sx={{ marginTop: '40px', marginBottom: '40px', textAlign: "center" }}>
                <Typography variant='body2' gutterBottom>Dsc. Oferta Estruturada %</Typography>
                <ErrorTooltip
                  name="Valorização Barter"
                  id="valorizacaoBarter"
                  value={dscOfertaEstruturada}
                  onChange={changeDscOferta}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  customInput={TextField}
                  size="small"
                  prefix={"-"}
                  suffix={"%"}
                  sx={{ div: { outline: 'unset' }, input: { textAlign: "right" }, width: "100%" }}
                  maxValue={(maxDscOfertaEstruturada).toFixed(1)}
                  messageError={`O valor precisa ser igual ou inferior a ${(maxDscOfertaEstruturada).toFixed(1)}%`}
                  allowNegative={false}
                />
              </Grid>
            </>
          }
          <Grid item xs={12} sm={4} md={mdSize} sx={{ marginTop: '40px', marginBottom: '40px', textAlign: "center" }}>
            <Typography variant='body2' gutterBottom>Encargos % (+)</Typography>
            <Typography variant='body1' mt={1} sx={{ fontWeight: "bold" }}> {encargos}% </Typography>
          </Grid>
        </>
        )}
    </>)
}
