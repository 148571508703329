/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { simulatorActions } from "../../../actions/simulator.actions";
import { productActions } from "../../../actions/products.actions";
import { productPriceActions } from "../../../actions/productprice.actions";
import { clientActions } from "../../../actions/client.actions";
import { campaignActions } from "../../../actions/campaign.actions";
import { payConditionsActions } from "../../../actions/paycondition.action";
import { packActions } from "../../../actions/pack.action";
import { descontopackActions } from "../../../actions/packdiscount.action";
import { produtopackActions } from "../../../actions/produtopack.action";
import { dateEncargosActions } from "../../../actions/dateencargos.action";
import { categoryDiscountActions } from "../../../actions/categorydiscount.action";
import { EncargosRatingsActions } from "../../../actions/encargosrate.actions";
import { GetAllDiscountCategoryActions } from "../../../actions/getalldiscountcategory.action";
import { encargosActions } from "../../../actions/encargos.action";
import { SaveCenarioActions } from "../../../actions/savecenario.actions";
import { SaveCenarioItemsActions } from "../../../actions/savecenarioitems.actions";
import { AllCampaignsActions } from "../../../actions/allcampaign.actions";
import { DeleteCenarioActions } from "../../../actions/deletecenario.action";
import { AnalyticsProductsActions } from "../../../actions/analyticsproducts.actions";
import { SaveGenerateCenarioActions } from "../../../actions/savegeneratecenario.actions";
import { ReactComponent as CrossSellSvg } from "../../../assets/svg/CrossSell.svg";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SimulatorTableEditable from "../../TableSimulator";
import { GetCenarioActions } from "../../../actions/getcenario.actions";
import Tooltip from '@mui/material/Tooltip';
import AllowUseNavigate, { navigate } from "../../Navigate";
import ConfirmAlert, { confirm } from "../../ConfirmAlert";
import { nivelSemaforoActions } from "../../../actions/nivelSemaforo.actions";
import { cenariosActions } from "../../../actions/cenario.actions";
import { userActions } from "../../../actions/user.actions";
import { zeroPad } from "../../../helpers/utils";

class SimulatorEditPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cliente: 0,
      clientId: "",
      cli: 0,
      produto: "",
      campanha: "",
      condicao: "",
      vencimento: "",
      encargos: "",
      currency: "",
      distrito: "",
      descontoCategoria: "",
      showCondition: false,
      showVencimento: false,
      arrayCondition: [],
      arrayDate: [],
      isCreatedCenario: false,
      idCenario: "",
      dataSelecionada: false,
      isFinalizado: false,
      setNewCenarioOptions: false,
    };
  }

  componentWillUnmount() {

    var finalizado = sessionStorage.getItem("isFinalizado");
    var excluido = sessionStorage.getItem("isExcluido");
    var canSave = this.state.campanha !== "" && this.state.condicao !== "" && this.state.vencimento !== "";

    if (finalizado !== "true" && excluido !== "true" && this.props.simulators.length > 0 && this.props.simulators?.filter((row) => !(row.excluido !== undefined && row.excluido)).length > 0 && canSave) {
      confirm({
        title: "Deseja salvar o cenário?",
        description: "O cenário será salvo como Rascunho e ficará disponivel para futuras edições.",
        confirmationText: "Sim, salvar",
        cancellationText: "Não, descartar alterações",
      })
        .then(() => {
          this.confirmSave()
        })
        .catch(() => {
          //console.log("saiu")
          this.props.actions.removeAllRows();
          sessionStorage.removeItem("cenarioId");
        });
    }
    else {
      sessionStorage.removeItem("cenarioId");
    }
    this.props.actions.removeAllProducts();
  }

  confirmSave() {
    // console.log("entrou")
    var cenarioId = sessionStorage.getItem("cenarioId");
    const rowCampaign = this.props.allcampanhas.AllCampaigns.find(
      (c) => this.state.campanha === c.campaignId
    );
    const Date = this.props.dateencargos.dateEncargos.find(
      (b) => b.id === this.state.vencimento
    );

    if (rowCampaign && Date) {
      const conprazo = this.props.encargos.encargos.find(
        (b) => b.paymentTermsId === Date.paymentTermsId
      );
      this.props.actions.createSaveGenerateCenarioAndClear(
        parseInt(cenarioId),
        rowCampaign.groupedCampaign,
        conprazo.paymentTerms,
        conprazo.fixedValueDate,
        this.state.cli
      );
    }
    sessionStorage.removeItem("cenarioId");
  }

  componentDidUpdate() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (!params.CenarioId && this.props.cenarios.SaveCenario) {
      // history.push(`/Simulador-Cliente?GroupId=${params.GroupId}&CenarioId=${this.props.cenarios.SaveCenario}`);
    }
  }

  componentWillMount() {
    // console.log("componentWillMount", this.props);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    // if (params.CenarioId === undefined) {
    //   this.props.actions.createSaveCenario(params.GroupId)
    // }
    this.props.getCenario.GetCenario = []
    this.setState({ cli: params.GroupId });
    if (params.CenarioId !== undefined || params.CenarioId != null) {
      this.props.actions.requestItensById(params.GroupId, params.CenarioId, false)
      this.setState({ "idCenario": params.CenarioId })
    }

    if (params.CloneId !== undefined || params.CloneId != null) {
      this.props.actions.requestItensById(params.GroupId, params.CloneId, true)
      this.setState({ "idCenario": "" })
    }

    this.props.actions.requestClients();
    this.props.actions.requestCampaigns();
    this.props.actions.requestPacks();
    this.props.actions.requestdescontopacks();
    this.props.actions.requestprodutopacks();
    this.props.actions.requestGetAllDiscountCategory();
    this.props.actions.requestAllCampaigns();
    this.props.actions.requestAllNivelSemaforoProduto();
    this.props.actions.requestRole();
    this.props.actions.requestRoleLevels();
    // this.props.actions.requestencargos()

    sessionStorage.setItem("isFinalizado", "false");
    sessionStorage.setItem("isExcluido", "false");

    // this.setState({idCenario: uuid()})

    if (this.state.campanha && this.state.produto !== "") {
      // console.log(this.state.produto)
      // this.props.actions.requestCategoryDiscount(this.state.campanha, this.state.produto,this.props.clientes.clients, this.state.clientId)
    }
  }

  handleChange = (event) => {
    this.setState({ produto: event.target.value });
    const produto = this.props.produtos.products.find(
      (b) => b.productId === event.target.value
    );
    if (this.state.campanha && event.target.value) {


      this.props.actions.requestCategoryDiscount(
        this.state.campanha,
        produto,
        this.props.clientes.clients,
        this.state.cli
      );
    }

    if (
      this.state.campanha &&
      this.state.condicao &&
      this.state.vencimento &&
      produto
    ) {
      this.props.actions.requestProductsPrice(
        this.state.campanha,
        this.props.clientes.clients,
        this.state.cliente,
        produto.product
      );
    }
  };

  handleChangeClient = (event) => {
    this.setState({ cliente: event.target.value });
  };

  handleChangeCampaign = (event) => {
    var a = 1;
    if (this.state.dataSelecionada) {
      this.setState({ vencimento: "" });
      this.setState({ dataSelecionada: false });
    }
    if (event.target.value) {
      const campaign = this.props.allcampanhas.AllCampaigns.find(
        (b) => b.campaignId === event.target.value
      );
      this.props.actions.requestencargos(campaign.groupedCampaign);
    }
    if (
      event.target.value &&
      this.state.condicao &&
      this.state.vencimento &&
      this.state.produto
    ) {
      a = 0;
    }
    if (a === 0) {
      // const Date = this.props.dateencargos.dateEncargos.find(
      //   (b) => b.id === this.state.vencimento
      // );

      this.props.actions.handleChangeCondition(event, this.props.simulators, 0);
    }
    this.setState({ campanha: event.target.value });
    const campaign = this.props.allcampanhas.AllCampaigns.find(
      (b) => b.campaignId === event.target.value
    );
    var oldCurrency = this.state.currency
    if (oldCurrency === "" || oldCurrency === undefined) {
      oldCurrency = campaign.currency
    }
    this.setState({ currency: campaign.currency })
    this.props.actions.requestPayConditions(campaign.groupedCampaign);

    this.props.actions.requestProductsAndUpdate(
      campaign.groupedCampaign,
      this.props.clientes.clients,
      this.state.cliente,
      campaign.currency,
      oldCurrency
    );

    this.setState({ showCondition: true });
  };

  //encargos
  handleChangePayCondition = (event) => {
    this.setState({ condicao: event.target.value });
    var venc = 1;
    if (this.state.vencimento) {
      this.setState({ vencimento: "" });
      venc = 0;
    }

    const TermName = this.props.condicaoPagamentos.payConditions.find(
      (b) => b.paymentTermsId === event.target.value
    );
    const campaign = this.props.allcampanhas.AllCampaigns.find(
      (b) => b.campaignId === TermName.campaignId
    );
    this.props.actions.requestdateEncargos(
      campaign.groupedCampaign,
      TermName.paymentTerms
    );

    const arrayDates = this.props.dateencargos.dateEncargos.filter(
      (b) => b.paymentTerms === TermName.paymentTerms
    );
    this.setState({
      arrayDate: arrayDates,
    });

    if (TermName && this.state.vencimento && venc === 1) {
      const Date = this.props.dateencargos.dateEncargos.find(
        (b) => b.id === this.state.vencimento
      );

      this.props.actions.requestEncargosRatings(
        TermName,
        Date.fixedValueDate,
        campaign.groupedCampaign
      );

      this.props.actions.handleChangeCondition(event, this.props.simulators, 0);
    }
    if (TermName && venc === 0) {
      const Date = this.props.dateencargos.dateEncargos.find(
        (b) => b.id === this.state.vencimento
      );

      this.props.actions.requestEncargosRatings(
        TermName,
        Date.fixedValueDate,
        campaign.groupedCampaign
      );

      this.props.actions.handleChangeCondition(event, this.props.simulators, 0);
    }

    if (
      this.state.campanha &&
      this.state.produto === "" &&
      this.state.dataSelecionada === false
    ) {
      const campaign = this.props.allcampanhas.AllCampaigns.find(
        (b) => b.campaignId === this.state.campanha
      );

      var territorio = "";
      var distrito = "";
      for (var j = 0; j < this.props.clientes.clients.length; j++) {
        // eslint-disable-next-line eqeqeq
        if (this.props.clientes.clients[j].id == this.state.cli) {
          this.state.cliente = this.props.clientes.clients[j].id;
          territorio = this.props.clientes.clients[j].territory;
          distrito = this.props.clientes.clients[j].district;
        }
      }

      this.props.actions.requestAnalyticsProducts(
        campaign.groupedCampaign,
        territorio,
        this.state.clientId,
        distrito
      );
    }
  };

  handleChangeDate = (event) => {
    this.setState({ vencimento: event.target.value });
    // var cenarioId = sessionStorage.getItem("cenarioId");

    // console.log(this.state.campanha);
    // console.log(this.state.condicao);
    // console.log(event.target.value);

    var rowss = !this.props.simulators.isLoading ? this.props.simulators : [];
    rowss = rowss.filter((row) => !(row.excluido !== undefined && row.excluido));

    if (this.state.dataSelecionada === false && rowss.length === 0) {
      const Date = this.props.dateencargos.dateEncargos.find(
        (b) => b.id === event.target.value
      );
      const conprazo = this.props.encargos.encargos.find(
        (b) => b.paymentTermsId === Date.paymentTermsId
      );


      this.props.actions.addRowAnalyticsProducts(
        this.props.analyticsProdutos.AnalyticsProducts,
        conprazo.rate,
        this.state.cliente,
        this.props.clientes.clients,
        this.state.idCenario,
        this.state.currency
      );
      this.setState({ dataSelecionada: true });
    } else if (this.state.dataSelecionada === false) {
      this.setState({ dataSelecionada: true });
    }
    if (event.target.value && this.state.condicao) {
      const Date = this.props.dateencargos.dateEncargos.find(
        (b) => b.id === event.target.value
      );
      const campaign = this.props.allcampanhas.AllCampaigns.find(
        (b) => b.campaignId === this.state.campanha
      );
      const conprazo = this.props.encargos.encargos.find(
        (b) => b.paymentTermsId === Date.paymentTermsId
      );

      this.props.actions.requestEncargosRatings(
        conprazo,
        Date.fixedValueDate,
        campaign.groupedCampaign
      );
      this.props.actions.handleChangeCondition(
        event,
        this.props.simulators,
        conprazo.rate
      );
    } else {
      if (event.target.value === 0 && this.state.condicao) {
        const Date = this.props.dateencargos.dateEncargos.find(
          (b) => b.id === event.target.value
        );
        const campaign = this.props.allcampanhas.AllCampaigns.find(
          (b) => b.campaignId === this.state.campanha
        );

        const conprazo = this.props.encargos.encargos.find(
          (b) => b.paymentTermsId === Date.paymentTermsId
        );

        this.props.actions.requestEncargosRatings(
          conprazo,
          Date.fixedValueDate,
          campaign.groupedCampaign
        );
        this.props.actions.handleChangeCondition(
          event,
          this.props.simulators,
          conprazo.rate
        );
      }
    }
    if (this.state.campanha && this.state.condicao && event.target.value >= 0) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params.CenarioId === undefined) {
        const rowCampaign = this.props.allcampanhas.AllCampaigns.find(
          (c) => this.state.campanha === c.campaignId
        );
        const Date = this.props.dateencargos.dateEncargos.find(
          (b) => b.id === event.target.value
        );
        const conprazo = this.props.encargos.encargos.find(
          (b) => b.paymentTermsId === Date.paymentTermsId
        );

        // const urlSearchParams = new URLSearchParams(window.location.search);
        // const params = Object.fromEntries(urlSearchParams.entries());
        this.props.actions.createSaveCenario(
          params.GroupId,
          rowCampaign.groupedCampaign,
          conprazo.paymentTerms,
          conprazo.fixedValueDate
        );
        // var cenarioIdNovo = sessionStorage.getItem("cenarioId");
        // history.push(`/Simulador-Cliente?GroupId=${params.GroupId}&CenarioId=${cenarioIdNovo}`);
      }
    }
  };

  addProduct = (
    e,
    produto,
    produtos,
    cliente,
    clientes,
    campanha,
    campanhas,
    condicao,
    condicoes,
    packs,
    descontosPacks,
    produtosPacks,
    linhas,
    descontoCat,
    precoProduto,
    encargosRate,
    actions
  ) => {
    var distrito = "";
    for (var j = 0; j < this.props.clientes.clients.length; j++) {
      // eslint-disable-next-line eqeqeq
      if (this.props.clientes.clients[j].id == this.state.cli) {
        this.state.cliente = this.props.clientes.clients[j].id;

        distrito = this.props.clientes.clients[j].district;
      }
    }
    this.setState({ isCreatedCenario: true });
    this.props.actions.addRow(
      e,
      produto,
      produtos,
      cliente,
      clientes,
      campanha,
      campanhas,
      condicao,
      condicoes,
      packs,
      descontosPacks,
      produtosPacks,
      linhas,
      descontoCat,
      precoProduto,
      encargosRate,
      actions,
      this.state.isCreatedCenario,
      this.state.idCenario,
      this.state.clientId,
      distrito,
      this.state.currency
    );
  };

  createCenario = () => {
    sessionStorage.setItem("isFinalizado", "true");
    var cenarioId = sessionStorage.getItem("cenarioId");

    const rowCampaign = this.props.allcampanhas.AllCampaigns.find(
      (c) => this.state.campanha === c.campaignId
    );
    const Date = this.props.dateencargos.dateEncargos.find(
      (b) => b.id === this.state.vencimento
    );

    if (rowCampaign && Date) {
      const conprazo = this.props.encargos.encargos.find(
        (b) => b.paymentTermsId === Date.paymentTermsId
      );
      this.props.actions.createSaveCenarioItemsAndClear(
        parseInt(cenarioId),
        rowCampaign.groupedCampaign,
        conprazo.paymentTerms,
        conprazo.fixedValueDate,
        this.state.cli
      );
    }

    this.props.actions.removeAllRows();
  };

  createCenarioAndSend = (comentario) => {
    sessionStorage.setItem("isFinalizado", "true");
    var cenarioId = sessionStorage.getItem("cenarioId");

    const rowCampaign = this.props.allcampanhas.AllCampaigns.find(
      (c) => this.state.campanha === c.campaignId
    );
    const Date = this.props.dateencargos.dateEncargos.find(
      (b) => b.id === this.state.vencimento
    );

    if (rowCampaign && Date) {
      const conprazo = this.props.encargos.encargos.find(
        (b) => b.paymentTermsId === Date.paymentTermsId
      );
      this.props.actions.createSaveCenarioItemsAndSend(
        parseInt(cenarioId),
        rowCampaign.groupedCampaign,
        conprazo.paymentTerms,
        conprazo.fixedValueDate,
        this.state.cli,
        comentario
      );
      this.props.actions.removeAllRows();
      navigate(`/MinhasSimulacoesCliente?GroupId=${this.state.cli}`)
    }

  };

  SaveCenario = () => {
    sessionStorage.setItem("isFinalizado", "true");
    var cenarioId = sessionStorage.getItem("cenarioId");

    const rowCampaign = this.props.allcampanhas.AllCampaigns.find(
      (c) => this.state.campanha === c.campaignId
    );
    const Date = this.props.dateencargos.dateEncargos.find(
      (b) => b.id === this.state.vencimento
    );

    if (rowCampaign && Date) {
      const conprazo = this.props.encargos.encargos.find(
        (b) => b.paymentTermsId === Date.paymentTermsId
      );
      this.props.actions.createSaveGenerateCenarioAndClear(
        parseInt(cenarioId),
        rowCampaign.groupedCampaign,
        conprazo.paymentTerms,
        conprazo.fixedValueDate,
        this.state.cli
      );
    }

    this.props.actions.removeAllRows();
  };

  deleteCenario = () => {
    sessionStorage.setItem("isExcluido", "true");
    navigate(`/MinhasSimulacoesCliente?GroupId=${this.state.cli}`)
    // var cenarioId = sessionStorage.getItem("cenarioId");

    // const urlSearchParams = new URLSearchParams(window.location.search);
    // const params = Object.fromEntries(urlSearchParams.entries());

    // const rowCampaign = this.props.allcampanhas.AllCampaigns.find(
    //   (c) => this.state.campanha === c.campaignId
    // );
    // const Date = this.props.dateencargos.dateEncargos.find(
    //   (b) => b.id === this.state.vencimento
    // );

    // var distrito = "";
    // for (var j = 0; j < this.props.clientes.clients.length; j++) {
    //   if (this.props.clientes.clients[j].id == this.state.cli) {
    //     this.state.cliente = this.props.clientes.clients[j].id;

    //     distrito = this.props.clientes.clients[j].district;
    //   }
    // }

    // if (rowCampaign && Date) {
    //   const conprazo = this.props.encargos.encargos.find(
    //     (b) => b.paymentTermsId === Date.paymentTermsId
    //   );

    //   this.props.actions.createDeleteCenario(
    //     parseInt(cenarioId),
    //     rowCampaign.groupedCampaign,
    //     conprazo.paymentTerms,
    //     conprazo.fixedValueDate
    //   );
    // }

    this.props.actions.removeAllRows();
  };

  handleClose() {
    this.setState({ openAlert: false });
  };

  handleLoadData() {
    if (this.state.cliente !== 0) {
      this.props.actions.requestProducts(
        this.props.getCenario.GetCenario.campanha,
        this.props.clientes.clients,
        this.state.cliente
      );

      var campanha = this.props.campanhas?.campaigns?.find(x => x.groupedCampaign === this.props.getCenario.GetCenario.campanha)?.campaignId;
      var condicao = this.props.condicaoPagamentos?.payConditions?.find(x => x.paymentTerms === this.props.getCenario.GetCenario.condicao)?.paymentTermsId;
      var vencimento = this.props.dateencargos?.dateEncargos?.find(x => x.fixedValueDate === this.props.getCenario.GetCenario.vencimento)?.id;
      this.setState({ "idCenario": this.props.getCenario.GetCenario.cenarioId })
      this.setState({ currency: this.props.getCenario.GetCenario.moeda });
      this.setState({ campanha: campanha });
      this.setState({ condicao: condicao });
      this.setState({ vencimento: vencimento });
      this.setState({ dataSelecionada: true });
      this.setState({ setNewCenarioOptions: true });
      this.props.actions.setCenario([]);
    }
  }

  render() {
    var cenarioId = this.state.idCenario === "" ? sessionStorage.getItem("cenarioId") : this.state.idCenario;
    var cli = "";
    var niveis = [];
    var role = "";
    var roleLevels = null;
    var territorio = ""
    var distrito = ""
    var csgLink = ""

    if (!this.props.user.isLoadingRole && this.props.user.role != null && !this.props.user.isLoadingRoleLevels && this.props.user.roleLevels != null) {
      roleLevels = this.props.user.roleLevels
      role = this.props.user.role.role
    }

    if (!this.props.clientes.isLoading)
      for (var j = 0; j < this.props.clientes.clients.length; j++) {
        // eslint-disable-next-line eqeqeq
        if (this.props.clientes.clients[j].id == this.state.cli) {
          var cliente = this.props.clientes.clients[j];
          this.state.cliente = cliente.id;
          this.state.clientId = cliente.clientId;
          cli = cliente.clientName;

          csgLink = `https://app.powerbi.com.mcas.ms/groups/83b365d1-ccde-4480-a7d3-bda8b9ca0769/reports/ad772169-03f1-4ac4-888d-ab4c5e1c00d4/ReportSection821cb735e70de341d5b2?filter=Clientes/group_id eq '${cliente.clientId}' and Clientes/territorio eq '${cliente.territory}'`

          if (!this.props.user.isLoadingRole && this.props.user.role != null && !this.props.user.isLoadingRoleLevels && this.props.user.roleLevels != null && role.accessLevel > roleLevels?.viewTerritory) {
            territorio = this.props.clientes.clients[j].territory;
            distrito = this.props.clientes.clients[j].district
          }
        }
      }
    var rowss = !this.props.simulators.isLoading ? this.props.simulators : [];
    rowss = rowss.filter((row) => !(row.excluido !== undefined && row.excluido));

    if (this.props.nivelSemaforo.isLoading === false) {
      niveis = this.props.nivelSemaforo.niveis.length === 0 ? [] : this.props.nivelSemaforo.niveis;
    }

    var total = 0;
    for (var i = 0; i < rowss.length; i++) {
      total = total + rowss[i].valorTotal;
    }
    var canShow = this.props.campanhas.isLoading ||
      this.props.clientes.isLoading ||
      this.props.produtos.isLoading ||
      this.props.condicaoPagamentos.isLoading ||
      this.props.dateencargos.isLoading ||
      (this.props.simulators.isLoading === undefined ? false : this.props.simulators.isLoading) ||
      this.props.nivelSemaforo.isLoading;

    if (!canShow && this.props.getCenario.GetCenario.length === undefined && !this.state.setNewCenarioOptions && this.props.clientes.clients.length > 0) {
      cenarioId = this.props.getCenario.GetCenario.cenarioId;
      this.handleLoadData();
    }

    var valores = rowss.filter(x => { return x.volume !== 0 && x.negotiatedPrice !== 0 });
    var valorFinal = valores.reduce((total, x) => total + Number(x.valorTotal), 0);
    var valorFinalNegociado = valores.reduce((total, x) => total + Number(x.negotiatedPrice), 0);

    var somaGm = valores.reduce((acc, item) => acc + item.gm, 0) / valorFinalNegociado;
    var gmTarget = valores.reduce((acc, obj) => {
      const product = obj.volume * obj.negotiatedPrice * (obj.gmTarget / 100);
      return acc + product;
    }, 0) / valorFinalNegociado;
    var gmPerActual = (somaGm / gmTarget) * 100;

    var somaFlex = valores.reduce((acc, obj) => {
      const product = obj.volume * obj.cortevaFinalPrice * (obj.flexibilidade / 100);
      return acc + product;
    }, 0)
      /
      valores.reduce((acc, obj) => {
        const product = obj.volume * obj.cortevaFinalPrice;
        return acc + product;
      }, 0);

    var flexTarget = valores.reduce((acc, obj) => {
      const product = obj.volume * obj.cortevaFinalPrice * (obj.flexibilidadeTargetMkt / 100);
      return acc + product;
    }, 0)
      /
      valores.reduce((acc, obj) => {
        const product = obj.volume * obj.cortevaFinalPrice;
        return acc + product;
      }, 0);

    var flexPerActual = 0;
    if (somaFlex < flexTarget) {
      flexPerActual = (Number(somaFlex) / Number(flexTarget)) * 100;
    }
    else {
      flexPerActual = (Number(somaFlex.toFixed(2)) / Number(flexTarget.toFixed(2))) * 100;
    }

    var nivel = undefined;
    nivel = niveis.find(x => {
      var ssMin = x.nivelSaudeSimulacao.minimo;
      var vSsMin = ssMin == null || gmPerActual >= ssMin;
      var ssMax = x.nivelSaudeSimulacao.maximo;
      var vSsMax = ssMax == null || gmPerActual < ssMax;

      var gmCorrect = vSsMin && vSsMax;

      var hSignal = x.nivelSaudeProduto.signal;
      var value = x.nivelSaudeProduto.valor;
      var flexCorrect = false;

      switch (hSignal) {
        case ">":
          flexCorrect = flexPerActual > value;
          break;
        case "<":
          flexCorrect = flexPerActual < value;
          break;
        case "=":
          flexCorrect = flexPerActual == value;
          break;
        default:
          break;
      }

      return gmCorrect && flexCorrect;
    });

    var canSave = this.state.campanha !== "" && this.state.condicao !== "" && this.state.vencimento !== ""
    var rascunho = !(rowss.length > 0 && (rowss.find(x => (x.volume === 0 || x.negotiatedPrice === 0)) === undefined))

    return (
      <div className="card">
        <AllowUseNavigate />
        <ConfirmAlert />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={canShow || this.props.analyticsProdutos.isLoading ||
            this.props.productPrice.isLoading ||
            this.props.descontoCategoriaSimulador.isLoading ||
            this.props.encargosRate.isLoading ||
            this.props.produtoPacks.isLoading ||
            this.props.packdiscounts.isLoading ||
            this.props.packs.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={`/MinhasSimulacoesCliente?GroupId=${this.state.cli}`}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/"}>
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={"/Simulador"}
              >
                Simulador
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={`/MinhasSimulacoesCliente?GroupId=${this.state.cli}`}
              >
                Minhas Simulações
              </Link>
              <Typography color="text.primary">Editar Simulação</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={8} md={6}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 style={{ color: "var(--cort-blue)" }}>{cli}</h1>
                <Tooltip title="Cross-Sell & Growth">
                  <a className="ml-2" target="_blank" href={csgLink} rel="noreferrer">
                    <CrossSellSvg height={25} fill="#000" />
                  </a>
                </Tooltip>
              </div>
              {(!(this.props.user.isLoadingRole || this.props.user.isLoadingRoleLevels) && role.accessLevel > roleLevels?.viewTerritory) &&
                <>
                  <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Território:</span> {territorio}</h6>
                  <h6 style={{ color: "var(--cort-blue)" }}><span style={{ fontWeight: "bold" }}>Distrito:</span> {distrito}</h6>
                </>
              }
              {cenarioId && (
                <h5 style={{ fontWeight: "bold" }}>
                  Cenário: #{zeroPad(cenarioId, 4)}
                </h5>
              )}
            </Grid>

            <Grid item xs={2} sm={8} md={6}>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                {total > 0 &&
                  <>
                    <Grid item xs={2} sm={8} md={8} sx={{ marginTop: { xs: 0, sm: 0, md: "14px" } }} className="align-self-center">
                      <h4>
                        <b>Valor total Simulação: </b>
                      </h4>
                    </Grid>
                    <Grid item xs={2} sm={8} md={4} sx={{ marginTop: { xs: 0, sm: 0, md: "14px" } }} className="align-self-center">
                      <h4>
                        {
                          total.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: (this.state.currency === "BRL" ? "BRL" : "USD"),
                          })
                        }
                      </h4>
                    </Grid>
                  </>
                }
                {total > 0 &&
                  <>
                    <Grid item xs={2} sm={8} md={8} className="align-self-center" sx={{ paddingTop: "0.5rem !important" }}>
                      <h4>
                        <b>Sua simulação foi qualificada com o score:</b>
                      </h4>
                    </Grid>
                    <Grid item xs={2} sm={8} md={4} sx={{ paddingTop: "0.5rem !important" }}>
                      <Stack
                        direction="row"
                        spacing={0}
                        justifyContent="flex-start"
                        className="border border-2 d-table p-2 rounded"
                        sx={{
                          borderColor: nivel ? `#${nivel?.cor === 0 ? "C00000" : nivel?.cor === 1 ? "FFC000" : "00B050"} !important` : "var(--bs-border-color) !important"
                        }}>
                        {/* <Typography className="border border-2 d-table p-2 rounded text-center" component="div" sx={{
                          borderColor: nivel ? `#${nivel?.cor === 0 ? "C00000" : nivel?.cor === 1 ? "FFC000" : "00B050"} !important` : "var(--bs-border-color) !important"
                        }}> */}
                        <CircleIcon sx={{ color: `${nivel?.cor === 2 ? "#00B050" : "var(--bs-border-color)"}`, fontSize: { xs: "clamp(1.75rem, 10vw, 2.5rem)", sm: "2.5rem", md: "clamp(1.75rem, 3.1vw, 2.5rem)" } }} />
                        <CircleIcon sx={{ color: `${nivel?.cor === 1 ? "#FFC000" : "var(--bs-border-color)"}`, fontSize: { xs: "clamp(1.75rem, 10vw, 2.5rem)", sm: "2.5rem", md: "clamp(1.75rem, 3.1vw, 2.5rem)" } }} />
                        <CircleIcon sx={{ color: `${nivel?.cor === 0 ? "#C00000" : "var(--bs-border-color)"}`, fontSize: { xs: "clamp(1.75rem, 10vw, 2.5rem)", sm: "2.5rem", md: "clamp(1.75rem, 3.1vw, 2.5rem)" } }} />
                        {/* </Typography> */}
                      </Stack>
                    </Grid>
                  </>

                }
              </Grid>
            </Grid>
            <Grid item xs={2} sm={8} md={12} >
              <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "2px", backgroundColor: "#e6e8ea", fontWeight: "bold" }}>
                <Grid item xs={12} sx={{ paddingTop: "1rem !important" }}>
                  <p>Selecione os parâmetros da Simulação:</p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={8} md={2.25}>
              <label className="d-block">Campanha</label>
              <Select
                value={this.state.campanha}
                placeholder="Campanha"
                onChange={this.handleChangeCampaign}
                sx={{ minWidth: 130, width: "100%" }}
                size="small"
              // disabled={rowss.length > 0}
              >
                {!this.props.campanhas.isLoading &&
                  this.props.campanhas.campaigns.map((campanha) => (
                    <MenuItem
                      key={`${campanha.campaignId}_${campanha.salesOrg}`}
                      value={campanha.campaignId}
                    >
                      {campanha.groupedCampaign}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={2} sm={8} md={1.5}>
              <label>Condição</label>
              <Select
                value={this.state.condicao}
                placeholder="Condição"
                onChange={this.handleChangePayCondition}
                sx={{ width: "100%" }}
                size="small"
              >
                {this.props.condicaoPagamentos.payConditions.map((condicao) => (
                  <MenuItem
                    key={condicao.paymentTermsId}
                    value={condicao.paymentTermsId}
                  >
                    {condicao.paymentTerms}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={2} sm={8} md={1.5}>
              <label>Vencimento</label>
              <Select
                value={this.state.vencimento}
                placeholder="Vencimento"
                onChange={this.handleChangeDate}
                sx={{ width: "100%" }}
                size="small"
              >
                {this.props.dateencargos.dateEncargos.map(
                  (dateencargos, index) => (
                    <MenuItem key={dateencargos.id} value={dateencargos.id}>
                      {dateencargos.formatDate}
                    </MenuItem>
                  )
                )}
              </Select>
            </Grid>
            <Grid item xs={2} sm={8} md={2.25}>
              <label style={{ marginBottom: "0" }}>Adicione produtos</label>
              <Stack
                direction={'row'}
                spacing={2}
                justifyContent="flex-start"
                alignItems="flex-end"
              >
                <Select
                  value={this.state.produto}
                  placeholder="Produto"
                  onChange={this.handleChange}
                  sx={{ width: "100%" }}
                  size="small"
                >
                  {!this.props.produtos.isLoading &&
                    this.props.produtos.products.map((produto) => (
                      <MenuItem key={produto.productId} value={produto.productId}>
                        {produto.product}
                      </MenuItem>
                    ))}
                </Select>

                <IconButton
                  color="primary"
                  size="large"
                  aria-label="Adicionar Produto"
                  onClick={(e) =>
                    this.addProduct(
                      e,
                      this.state.produto,
                      this.props.produtos.products,
                      this.state.cliente,
                      this.props.clientes.clients,
                      this.state.campanha,
                      this.props.allcampanhas.AllCampaigns,
                      this.state.condicao,
                      this.props.condicaoPagamentos.payConditions,
                      this.props.packs.packs,
                      this.props.packdiscounts.descontopacks,
                      this.props.produtoPacks.produtopacks,
                      this.props.simulators,
                      this.props.descontoCategoriaSimulador.CategoryDiscount.categoryDiscount,
                      this.props.productPrice.ProductsPrice,
                      this.props.encargosRate.EncargosRatings,
                      this.props.actions
                    )
                  }
                >
                  <AddShoppingCartIcon />
                </IconButton>
              </Stack>
            </Grid>
            <Grid item xs={2} sm={8} md={1.5} sx={{ alignSelf: "flex-end" }}>
              {canSave ?
                <Link to={`/MinhasSimulacoesCliente?GroupId=${this.state.cli}`}>
                  <Button
                    onClick={() => this.SaveCenario()}
                    variant="contained"
                    color="laranja"
                    sx={{ padding: "6.5px 16px", width: "100%", height: "100%", minHeight: "40px" }}
                  >
                    Salvar Rascunho
                  </Button>
                </Link> :
                <Tooltip title="Não é possivel salvar o cenário sem condição de pagamento e vencimento">
                  <span>
                    <Button
                      disabled={true}
                      variant="contained"
                      color="laranja"
                      sx={{ padding: "6.5px 16px", width: "100%", height: "100%", minHeight: "40px" }}
                    >
                      Salvar Rascunho
                    </Button>
                  </span>
                </Tooltip>
              }
            </Grid>
            <Grid item xs={2} sm={8} md={1.5} sx={{ alignSelf: "flex-end" }}>
              {!rascunho && canSave ?
                <Link to={`/MinhasSimulacoesCliente?GroupId=${this.state.cli}`}>
                  <Button
                    onClick={() => this.createCenario()}
                    variant="contained"
                    color="verde"
                    sx={{ padding: "6.5px 16px", width: "100%", height: "100%", minHeight: "40px" }}
                  >
                    Finalizar Cenário
                  </Button>
                </Link> :
                <Tooltip title="Preencha todos os campos para finalizar o cenário">
                  <span>
                    <Button
                      disabled={true}
                      variant="contained"
                      color="verde"
                      sx={{ padding: "6.5px 16px", width: "100%", height: "100%", minHeight: "40px" }}
                    >
                      Finalizar Cenário
                    </Button>
                  </span>
                </Tooltip>

              }
            </Grid>
            {/* <Grid item xs={2} sm={8} md={3} sx={{ alignSelf: "flex-end" }}>
              {!rascunho && canSave ?
                <ActionDialogButton aria-label="Enviar" variant="contained"
                  color={"verde"}
                  title={"Deseja realmente enviar o cenário para aprovação?"}
                  message={"Insira uma justificativa sobre o envio:"}
                  onClick={(comentario) => { this.createCenarioAndSend(comentario) }}
                  sx={{ padding: "6.5px 16px", width: "100%", height: "100%" }}
                >
                  Enviar para aprovação
                </ActionDialogButton>
                :
                <Tooltip title="Preencha todos os campos para enviar o cenário para aprovação">
                  <span>
                    <Button
                      disabled={true}
                      variant="contained"
                      color="verde"
                      sx={{ padding: "6.5px 16px", width: "100%", height: "100%" }}
                    >
                      Enviar para aprovação
                    </Button>
                  </span>
                </Tooltip>
              }
            </Grid> */}
            <Grid item xs={2} sm={8} md={1.5} sx={{ alignSelf: "flex-end" }}>
              <Button
                variant="contained"
                color="vermelho"
                onClick={() => this.deleteCenario()}
                sx={{ padding: "6.5px 16px", width: "100%", height: "100%", minHeight: "40px" }}
              >
                Sair sem Salvar
              </Button>
            </Grid>
            {this.props.simulators.length > 0 && (
              <Grid item xs={2} sm={8} md={12} >
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 8, md: 12 }} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "0 !important", color: "white", backgroundColor: "var(--cort-blue)", fontWeight: "bold" }}>
                  <Grid item xs={2} sm={1.5} md={2.5} sx={{ textAlign: { xs: "center", sm: "left" }, paddingTop: "1rem !important" }}>
                    <p>Cesta do Cliente</p>
                  </Grid>
                  <Grid item xs={2} sm={5.5} md={8} sx={{ textAlign: "center", paddingTop: "1rem !important" }}>
                    <p>(Informe os dados de Volume e Preço Negociado)</p>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={2} sm={8} md={12}>
              {this.props.simulators.length > 0 && !this.props.simulators.isLoading &&
                <SimulatorTableEditable actions={this.props} state={this.state} />}
              {this.props.simulators.length === 0 && (
                <p>Use a caixa de seleção acima para iniciar uma simulação</p>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators(
        {
          ...simulatorActions,
          ...productActions,
          ...clientActions,
          ...campaignActions,
          ...payConditionsActions,
          ...packActions,
          ...descontopackActions,
          ...produtopackActions,
          ...dateEncargosActions,
          ...categoryDiscountActions,
          ...productPriceActions,
          ...EncargosRatingsActions,
          ...GetAllDiscountCategoryActions,
          ...encargosActions,
          ...GetCenarioActions,
          ...SaveCenarioActions,
          ...SaveCenarioItemsActions,
          ...AllCampaignsActions,
          ...DeleteCenarioActions,
          ...AnalyticsProductsActions,
          ...SaveGenerateCenarioActions,
          ...nivelSemaforoActions,
          ...cenariosActions,
          ...userActions
        },
        dispatch
      ),
    };
  }
)(SimulatorEditPage);
