const requestAllOrdensDesmembramentos = "REQUEST_ALL_ORDENS_DESMEMBRAMENTO";
const receiveAllOrdensDesmembramentos = "RECEIVE_ALL_ORDENS_DESMEMBRAMENTO";
const initialState = { ordensDesmembramento: [], isLoading: false };

export const ordensDesmembramentoActions = {
  requestAllOrdens: () => async (dispatch, getState) => {
    const { oidc } = getState();

    dispatch({ type: requestAllOrdensDesmembramentos });

    const url = `/api/desmembramento/ordens`;

    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${oidc.user.access_token}`,
      },
    };

    const response = await fetch(url, options);
    const ordensDesmembramento = await response.json();
    dispatch({ type: receiveAllOrdensDesmembramentos, ordensDesmembramento });
  },
};

export const reducer = (state, action) => {
  state = state || initialState;

  if (action.type === requestAllOrdensDesmembramentos) {
    return {
      ...state,
      isLoading: true,
    };
  }
  if (action.type === receiveAllOrdensDesmembramentos) {
    return {
      ...state,
      ordensDesmembramento: action.ordensDesmembramento,
      isLoading: false,
    };
  }

  return state;
};
