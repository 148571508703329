/* eslint-disable eqeqeq */
import React from "react";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Stack from "@mui/material/Stack";
import { Box, Collapse, IconButton } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import {
  theme,
  themeCollapse,
  HtmlTooltipTable
} from "./HelpersComponents";
import { GetSafeNumber } from "../../../../../helpers";
import { formatCpfCnpj, formatDate } from "../../../../../helpers/utils";

function ResumeProdutoTable({ itens, formulario, ...props }) {
  const [rowHeight, setRowHeight] = React.useState(0);
  const rows = formulario.cenarioFormularioAccount.flatMap(x => x.cenarioFormularioAccountItens);
  const rowRef = React.useCallback(node => {
    if (node !== null) {
      [...node.childNodes].forEach(({ offsetHeight = 0 }) => {
        if (rowHeight < offsetHeight) {
          setRowHeight(() => offsetHeight)
        }
        node.style.height = `${rowHeight}px`;
      })
    }
  }, [rowHeight]);

  return (
    <TableContainer component={Paper}>
      <ThemeProvider theme={(mainTheme) => createTheme({ ...mainTheme, ...theme, palette: { ...mainTheme.palette } })}>
        <Table size="small" aria-label="custom pagination table">
          <TableHead>
            <TableRow sx={{
              "th": {
                whiteSpace: "nowrap",
                fontWeight: "bold"
              },
            }}>
              <TableCell>Produto</TableCell>
              <TableCell align="right">Volume Preenchido</TableCell>
              <TableCell align="right">Volume Restante</TableCell>
              <TableCell align="right">Total Preenchido</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itens.map((row, i) => {
              var total = rows.filter((y) => y.itensCenarioId === row.id)
                .reduce((sum, y) => sum += y.volume, 0);

              var per = (total / row.volume) * 100;
              per = (!isFinite(per) || isNaN(per)) ? 0 : per;

              var restante = (row.volume - total);
              restante = (!isFinite(restante) || isNaN(restante)) ? 0 : restante;

              return (
                <TableRow key={i} ref={rowRef}>
                  <TableCell>
                    {row.produto}
                  </TableCell>
                  <TableCell align="right">
                    {total.toFixed(2).toLocaleString("pt-BR", {
                      style: "currency",
                    })}
                  </TableCell>
                  <TableCell align="right">
                    {restante.toFixed(2).toLocaleString("pt-BR", {
                      style: "currency",
                    })}
                  </TableCell>
                  <TableCell align="right">
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <div>
                        {(per > 100) && <ErrorIcon color="vermelho" />}
                      </div>
                      <div style={{ whiteSpace: "nowrap" }}>
                        {per.toFixed(2).toLocaleString("pt-BR", {
                          style: "currency",
                        })}%
                      </div>
                    </Stack>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </ThemeProvider>
    </TableContainer>
  );
}

function RowResume({ row, itens, currency, municipio, control, formulario, setValue = (name = "", value) => { }, getValues, ...props }) {
  const allValues = formulario?.cenarioFormularioAccount?.every(x => !(!x.allValueSame && x.allValueSame !== undefined))

  return (
    <TableRow>
      <TableCell colSpan={5} />
      <TableCell>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <div>Percentual Volume Total:</div>

          <div style={{ whiteSpace: "nowrap" }}>
            <HtmlTooltipTable placement="top"
              title={
                <React.Fragment>
                  <ResumeProdutoTable itens={itens} formulario={formulario} />
                </React.Fragment>
              }
            >
              {allValues ? <InfoOutlinedIcon color={"azul"} /> : <ErrorOutlineIcon color={"amarelo"} />}
            </HtmlTooltipTable>
          </div>
        </Stack>
      </TableCell>
      <TableCell />
    </TableRow>
  );
}

function Row({ formulario, rows, row, index = 0, itens, isSeeds = false, currency, situacaoEspecial = [], metodoPagamento, ...props }) {
  const [open, setOpen] = React.useState(false);
  const [rowHeight, setRowHeight] = React.useState(0);

  const rowRef = React.useCallback(node => {
    if (node !== null) {
      [...node.childNodes].forEach(({ offsetHeight = 0 }) => {
        if (rowHeight < offsetHeight) {
          setRowHeight(() => offsetHeight)
        }
        node.style.height = `${rowHeight}px`;
      })
    }
  }, [rowHeight]);

  const CalcTotal = (values = []) => {
    return values.reduce((total, item) => {
      var row = itens.find(x => x.id === item.itensCenarioId)
      var valor = (isSeeds ? item.volume : item.volumeReal) * row?.precoNegociado
      return total = total + valor
    }, 0)
  }

  const formularioAccount = formulario.cenarioFormularioAccount[index];

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset !important' } }}>
        <TableCell sx={{ padding: "6px 6px 6px 6px !important", minWidth: 0, width: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.shipTo?.customerNumber} - {row.account?.cliente}</TableCell>
        <TableCell>{row.account?.municipio}/{row.account?.uf}</TableCell>
        <TableCell>{formatCpfCnpj(row.account?.cpfCnpjDoCliente)}</TableCell>
        <TableCell>{formularioAccount?.instrucaoEntrega}</TableCell>
        <TableCell align="right">{formularioAccount?.percentual}</TableCell>
        <TableCell align="right">
          {
            CalcTotal(formularioAccount?.cenarioFormularioAccountItens).toLocaleString("pt-BR", {
              style: "currency",
              currency: (currency === "BRL" ? "BRL" : "USD"),
            })
          }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <ThemeProvider theme={(mainTheme) => createTheme({ ...mainTheme, ...theme, palette: { ...mainTheme.palette } })}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="custom pagination table">
                  <TableHead>
                    <TableRow
                      sx={{
                        "th": {
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                          color: "#fff"
                        },
                        backgroundColor: "var(--cort-blue)",
                        color: "#fff"
                      }}>
                      <TableCell>Produto</TableCell>
                      {!isSeeds ?
                        <TableCell>Material</TableCell> :
                        <TableCell>Sufixo</TableCell>
                      }
                      <TableCell align="right">Volume</TableCell>
                      {!isSeeds && <>
                        <TableCell>Embalagem (cx/sc)</TableCell>
                        <TableCell>Volume Real</TableCell>
                      </>
                      }
                      <TableCell>Data de Entrega</TableCell>
                      <TableCell align="right">Preço Negociado</TableCell>
                      <TableCell align="right">Valor Final</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formularioAccount?.cenarioFormularioAccountItens?.map((item, i) => {
                      const row = itens.find(x => x.id === item.itensCenarioId);

                      return (
                        <TableRow key={item.key} ref={rowRef}>
                          <TableCell>
                            {row?.produto}
                          </TableCell>

                          <TableCell>
                            {!isSeeds && (item.materiais?.find(m => m.materialId == item.material)?.materialName ?? "")}
                            {isSeeds && (item.materiais?.find(m => m.id == item.material)?.sufixo ?? "")}
                          </TableCell>

                          <TableCell align="right">
                            {item.volume}
                          </TableCell>

                          {!isSeeds && (
                            <>
                              <TableCell>
                                {item.qtdPedido}
                              </TableCell>

                              <TableCell align="left">
                                {GetSafeNumber(item.volumeReal, 2).toLocaleString("pt-BR")}
                              </TableCell>
                            </>
                          )}

                          <TableCell>
                            {formatDate(item.dataEntregaSol)}
                          </TableCell>

                          <TableCell align="right">
                            {row?.precoNegociado?.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: (currency === "BRL" ? "BRL" : "USD"),
                            })}
                          </TableCell>

                          <TableCell align="right">
                            {isSeeds ?
                              (item.volume * row?.precoNegociado)?.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: currency === "BRL" ? "BRL" : "USD",
                              }) :
                              (item.volumeReal * row?.precoNegociado)?.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: currency === "BRL" ? "BRL" : "USD",
                              })}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </ThemeProvider>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment >
  );
}

export function TableClientesView({ rows, itens = [], formulario, currency, isSeeds = false, situacaoEspecial = [], metodoPagamento }) {
  return (
    <Box>
      <TableContainer component={Paper}>
        <ThemeProvider theme={(mainTheme) => createTheme({ ...mainTheme, ...themeCollapse, palette: { ...mainTheme.palette } })}>
          <Table aria-label="collapsible table">
            <TableHead >
              <TableRow sx={{
                "th": {
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                  color: "#fff"
                },
                backgroundColor: "var(--cort-blue)",
                color: "#fff"
              }}>
                <TableCell sx={{ padding: "6px 6px 6px 6px !important", minWidth: "0" }} />
                <TableCell>Cliente</TableCell>
                <TableCell>Município/UF</TableCell>
                <TableCell>CPF/CNPJ</TableCell>
                <TableCell>Instruções de entrega</TableCell>
                <TableCell align="right">Percentual Volume %</TableCell>
                <TableCell align="right">Valor Final</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, i) => {
                return (
                  <Row key={row.newKey}
                    index={i}
                    row={row}
                    itens={itens}
                    currency={currency}
                    rows={rows}
                    isSeeds={isSeeds}
                    situacaoEspecial={situacaoEspecial}
                    formulario={formulario}
                    metodoPagamento={metodoPagamento}
                  />
                )
              })}
              <RowResume
                itens={itens}
                currency={currency}
                formulario={formulario}
              />
            </TableBody>
          </Table>
        </ThemeProvider>
      </TableContainer>
    </Box>
  );
}