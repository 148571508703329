import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { userActions } from '../../../../actions/user.actions';
import { adminUserActions } from "../../../../actions/admin/user.actions";
import { adminWhitespaceActions } from "../../../../actions/admin/whitespace.actions";

import Stack from "@mui/material/Stack";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import PersonIcon from '@mui/icons-material/Person';

class AdminWhitespacePage extends React.Component {
  render() {
    return (
      <div className="card" style={{ borderRadius: 0 }}>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <ArrowBackIcon color="transparent" />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="text.primary">Whitespace</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <Stack direction="column" spacing={4} style={{ textAlign: 'center' }}>
            <h1 style={{ fontSize: '2rem', fontWeight: 'bold', color: 'var(--cort-blue)' }}>Configurações Whitespace</h1>
            <Stack direction="column" spacing={2} style={{ textAlign: 'center' }}>
              <Link to={"/Admin-Account"}>
                <Button variant="contained" startIcon={<PersonIcon style={{ fontSize: '1.5rem' }} />} style={{ width: '100%', fontWeight: 'bold', fontSize: '1.1rem' }}>
                  Configurações Vu's
                </Button>
              </Link>
              <Link to={"/Admin-Culture"}>
                <Button variant="contained" startIcon={<LocalFloristIcon style={{ fontSize: '1.5rem' }} />} style={{ width: '100%', fontWeight: 'bold', fontSize: '1.1rem' }}>
                  Culturas
                </Button>
              </Link>
              <Link to={"/Admin-Safra"}>
                <Button variant="contained" startIcon={<AgricultureIcon style={{ fontSize: '1.5rem' }} />} style={{ width: '100%', fontWeight: 'bold', fontSize: '1.1rem' }}>
                  Safras
                </Button>
              </Link>
            </Stack>
          </Stack>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions: bindActionCreators({
        ...adminUserActions,
        ...adminWhitespaceActions,
        ...userActions,
      }, dispatch),
    };
  }
)(AdminWhitespacePage);