import { isNullOrEmpty } from "../../../../../../helpers";
import { zeroPad } from "../HelpersComponents"
import DataTable from "./DesmembramentoPrintable";

export const PrintPage = ({ cenario, cliente, moeda, formulario, metodoPagamentoCp, situacaoEspecial, allMetodos }) => {
    return (
        <>
            <div style={{ marginTop: "16px", display: 'grid', gap: '16px', gridTemplateColumns: 'repeat(12, 1fr)', alignItems: 'center' }}>
                <h3 style={{ gridColumn: 'span 2', fontWeight: 'bold', margin: 0 }}>Simulação #{zeroPad(cenario.id, 4)}</h3>
                <h3 style={{ gridColumn: 'span 2', fontWeight: 'bold', margin: 0 }}>Distrito: {cliente?.distrito ?? ""}</h3>
                <h3 style={{ gridColumn: 'span 3', fontWeight: 'bold', margin: 0 }}>Território: {cliente?.territorio ?? ""}</h3>
                <div style={{ gridColumn: 'span 5' }}></div>

                <h3 style={{ gridColumn: 'span 2', fontWeight: 'bold', margin: 0 }}>Cliente:</h3>
                <h3 style={{ gridColumn: 'span 2', fontWeight: '400', margin: 0 }}>{cliente?.nomeVisaoUnica ?? ""}</h3>

                <h3 style={{ gridColumn: 'span 2', fontWeight: 'bold', margin: 0 }}>Valor total Simulação:</h3>
                <h3 style={{ gridColumn: 'span 3', fontWeight: '400', margin: 0 }}>
                    {cenario?.totalSimulacao?.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: moeda === "BRL" ? "BRL" : "USD",
                    })}
                </h3>
            </div>

            {cenario.isCpActive && cenario?.cenarioV2Cp && formulario?.formularioCp &&
                <DataTable
                    title="CROP PROTECTION"
                    isSeeds={false}
                    currency={cenario?.moeda}
                    cenario={cenario?.cenarioV2Cp}
                    formulario={formulario?.formularioCp}
                    metodoPagamento={metodoPagamentoCp}
                    situacaoEspecial={situacaoEspecial}
                />
            }

            {cenario.isSeedsActive && !isNullOrEmpty(cenario?.cenarioV2Seeds) && !isNullOrEmpty(formulario?.formularioSeeds) && (
                <>
                    <div style={{ marginTop: "16px", fontSize: "13px", color: "white", backgroundColor: "#0072ce", borderRadius: "8px", padding: "1rem" }}>
                        <b>SEEDS</b>
                    </div>

                    {formulario.formularioSeeds.map((formulario) => {
                        const cenarioSeed = cenario?.cenarioV2Seeds.find(c => c.cultura == formulario.cultura)
                        const metodoPagamentoSeedsItem = allMetodos.find(metodo => metodo.metodoPagamentoId === cenarioSeed.condicao);
                        return (
                            <DataTable
                                title={formulario.cultura.toUpperCase()}
                                isSeeds={true}
                                currency={cenario?.moeda}
                                cenario={cenarioSeed}
                                formulario={formulario}
                                situacaoEspecial={situacaoEspecial}
                                metodoPagamento={metodoPagamentoSeedsItem}
                            />
                        )
                    })}
                </>
            )}
        </>
    )
}