import * as React from 'react';
import PropTypes from 'prop-types';
// import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
// import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
// import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import WidgetsIcon from '@mui/icons-material/Widgets';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import Container from '@mui/material/Container';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import MapIcon from '@mui/icons-material/Map';
import NavBar from './Navbar';
import { Link } from 'react-router-dom';
import AssessmentIcon from '@mui/icons-material/Assessment';

const drawerWidth = 200;

function AdminNavBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div style={{ height: "calc( 100% - 56px)" }}>
      <List>
        <Link to={"/Admin"}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary={"Usuários"} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to={"/Admin-Modulo"}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ViewModuleIcon />
              </ListItemIcon>
              <ListItemText primary={"Módulos"} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to={"/Admin-Whitespace"}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <MapIcon />
              </ListItemIcon>
              <ListItemText primary={"Whitespace"} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to={"/Admin-Stock"}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <WidgetsIcon />
              </ListItemIcon>
              <ListItemText primary={"Estoque"} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to={"/Admin-Desmembramento"}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <BackupTableIcon />
              </ListItemIcon>
              <ListItemText primary={"Ordens"} />
            </ListItemButton>
          </ListItem>
        </Link>
        <Link to={"/Admin-SimuladorVendaExterna"}>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary={"Simulador Venda Externa"} />
            </ListItemButton>
          </ListItem>
        </Link>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <NavBar oidc={props.oidc} closeDrawer={handleDrawerToggle} connection={props.connection} />
      <Box sx={{ display: 'flex', backgroundColor: "#e6e8ea !important" }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },

          }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'block', md: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                backgroundColor: "var(--cort-blue)",
                color: "#FFF",
                '& *': { color: "#FFF" },
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'none', md: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                backgroundColor: "var(--cort-blue)",
                color: "#FFF",
                '& *': { color: "#FFF" },
                height: "calc(100% - 59px)",
                top: "unset"
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        {/* <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          display: { xs: 'none', sm: 'none', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          backgroundColor: "var(--cort-blue)",
          color: "#FFF",
          '& *': { color: "#FFF" },
        }}
        aria-label="mailbox folders"
      >
        {drawer}
      </Box> */}
        <Container component="main"
          sx={{
            flexGrow: 1,
            p: "0 !important", width: {
              sm: 'calc(100%)',
              md: `calc(100% - ${drawerWidth}px)`,
            },
            display: "flex",
            position: "absolute",
            right: 0,
            height: "calc(100% - 59px)",
            backgroundColor: "#e6e8ea !important",
            maxWidth: "100% !important "
          }}
        >
          <div style={{ overflow: "overlay", flex: "1" }}>
            {props.children}
          </div>
        </Container>
      </Box>
    </>
  );
}

AdminNavBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default AdminNavBar;