import { Chip, Collapse, Grid, IconButton, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { isNullOrUndefined } from "../../../../../helpers/utils";
import { isNullOrEmpty } from "../../../../../helpers";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--cort-blue)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export function InfoDesmembramentoTable({ requisition }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Grid item xs={2} sm={8} md={12} >
        <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "18px", color: "white", backgroundColor: "var(--cort-blue)", fontWeight: "bold" }}>
          <Grid item xs={12} sx={{ paddingTop: "calc(1rem - 8px) !important" }} >
            <IconButton
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon sx={{ color: "#fff" }} /> : <KeyboardArrowDownIcon sx={{ color: "#fff" }} />}
            </IconButton>
            <p style={{ display: "inline-flex", marginLeft: "8px" }}>Detalhes do envio</p>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} sm={8} md={12} >
        <Collapse in={open} timeout="auto" unmountOnExit sx={{ marginBottom: "16px" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: "300px" }}>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Número do Pedido</StyledTableCell>
                  <StyledTableCell>Código do Cliente</StyledTableCell>
                  <StyledTableCell>Produto</StyledTableCell>
                  <StyledTableCell align="left">Descrição</StyledTableCell>
                  <StyledTableCell align="center">Volume</StyledTableCell>
                  <StyledTableCell align="center">Situação</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isNullOrUndefined(requisition) && !isNullOrEmpty(requisition?.flatMap(x => x.products)) ? (
                  requisition.flatMap(row => {
                    const { products } = row;

                    if (isNullOrEmpty(products)) return null;

                    return products.map(product => (
                      <StyledTableRow key={`${product.productName}-${product.measurementValue}`}>
                        <StyledTableCell>{product.numberSAP}</StyledTableCell>
                        <StyledTableCell>{row.partnerIdentifier}</StyledTableCell>
                        <StyledTableCell>{product.productName}</StyledTableCell>
                        <StyledTableCell align="left">{product.productDescription}</StyledTableCell>
                        <StyledTableCell align="center">{product.measurementValue}</StyledTableCell>
                        <StyledTableCell align="center">
                          <Chip
                            label={product.specialInstructions}
                            variant="outlined"
                            color={product.specialInstructions === "APROVADO" ? "success" : "error"}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    ));
                  }).filter(Boolean)
                ) : (
                  <StyledTableRow key="NoInfo">
                    <StyledTableCell align="center" colSpan={6} sx={{ height: "300px" }}>
                      Nenhuma informação disponível
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </Grid>
    </>
  )
}