/* eslint-disable eqeqeq */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  Button, Box, Stack, IconButton,
  Typography, Collapse, Grid,
  MenuItem, Select,
  TextField
}
  from '@mui/material';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import DeleteIcon from '@mui/icons-material/Delete';

import { simulatorSeedsActions } from '../../../../actions/simulatorSeeds.actions';
import { productActions } from '../../../../actions/products.actions';
import { GetSafeNumber, isNullOrEmpty } from "../../../../helpers";
import SimuladorTableSeeds from "./SimuladorTableSeeds";
import Semaforo from "../components/Semaforo";
import { ErrorTooltip } from "../components/ErrorTooltip";
import ConfirmationDialog from "../components/SelectNewProduto";
import { simulator2Actions } from "../../../../actions/simulator2.actions";
import { removeObjectDuplicates, toDateTimeFormat } from "../../../../helpers/utils";

class SimulatorFormSeeds extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cenarioId: "",
      cliente: 0,
      groupId: "",
      distrito: "",
      territorio: "",
    };

    this.handleChangeSelect = this.handleChangeSelect.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.simulator2?.clientes?.client !== this.props.simulator2?.clientes?.client || (this.props.simulator2?.clientes?.client && this.state.cliente === 0)) {
      const { client: cliente } = this.props.simulator2.clientes;
      if (cliente) {
        this.setState({ cliente: cliente.id })
        this.setState({ groupId: cliente.grupoId })
        this.setState({ distrito: cliente.distrito })
        this.setState({ territorio: cliente.territorio })
        this.setState({ classificacaoSeeds: cliente?.classificacaoClienteSeeds?.split(",") })
      }
    }
  }

  processEncargos(section, selectedDate) {
    const encargos = this.props.encargos.allEncargos;
    const encargosDates = section?.campanha?.groupedCampaign !== undefined ? encargos?.filter(x => section?.campanha?.groupedCampaign == (x.assignmentNumber + "_" + x.materialGroupDscp)) : [];

    let vencimento = selectedDate ? selectedDate : section.vencimento;
    let rates = encargosDates.filter(x => x.tableName == "A691" && x.salesOfficeDscp == this.state.distrito && x.conditionGroupDscp == vencimento).sort((a, b) => a.rate - b.rate);

    if (rates?.length <= 0) {
      rates = encargosDates.filter(x => x.tableName == "A609" && x.conditionGroupDscp == vencimento).sort((a, b) => a.rate - b.rate);

      if (rates?.length <= 0) {
        rates = encargosDates.filter(x => x.tableName == "A687" && x.salesOfficeDscp == this.state.distrito && x.conditionGroupDscp == vencimento).sort((a, b) => a.rate - b.rate);

        if (rates?.length <= 0) {
          rates = encargosDates.filter(x => x.tableName == "A602" && x.conditionGroupDscp == vencimento).sort((a, b) => a.rate - b.rate);
        }
      }
    }

    const rate = rates[0]?.rate ?? 0;
    return {
      encargosDates: removeObjectDuplicates(encargosDates, "conditionGroupDscp"),
      rate
    };
  }

  handleChangePaymentTerm(section, value) {
    this.props.actions.simuladorSeeds.handleChangePaymentTerm(
      section.id,
      value
    )
  }

  handleChangePaymentDate(section, value) {
    const { rate } = this.processEncargos(section, value);
    const notCreated = isNullOrEmpty(section?.rows);

    this.props.actions.simuladorSeeds.handleChangePaymentDate(
      section.id,
      value,
      this.state.groupId,
      section.campanha.groupedCampaign,
      this.state.territorio,
      this.state.distrito,
      section.cultura,
      section.condicao,
      !notCreated
    ).then(() => {
      if (notCreated) {
        this.props.actions.simuladorSeeds.addAnalyticsRows(section.id, rate, section.campanha.currency, section.condicao);
      }
      else {
        this.props.actions.simuladorSeeds.updateRows(section.id, rate, section.campanha.currency, section.condicao);
      }

      if (this.props.simulator2.simulatorsData.id === undefined) {
        this.props.actions.simulador.createCenario();
      }
    });
  }

  handleChangeSelect(section, row, name, value) {
    if (name === 'sufixo') {
      this.props.actions.simuladorSeeds.handleChangeSuffix(section, row, value);
    }
    else {
      this.props.actions.simuladorSeeds.handleChangeValue(section, row, name, value);
    }
  }

  handleChangeProduct(id, section) {
    const { rate } = this.processEncargos(section);
    const product = section.produtos.find((b) => id === b.productId);
    this.props.actions.simuladorSeeds.addRow(
      section.id,
      product,
      rate,
      section.campanha?.currency,
      section.descontos,
      section.campanhaGroup,
      this.state.territorio,
      this.state.groupId,
      this.state.distrito,
      section.cultura,
      section.condicao,
      section.vencimento
    );
  }

  handleChangeBarter(sectionId, value) {
    this.props.actions.simuladorSeeds.handleChangeBarter(sectionId, value);
  }

  handleChangeAdimplencia(sectionId, value) {
    this.props.actions.simuladorSeeds.handleChangeAdimplencia(sectionId, value);
  }

  calcScore(rows) {
    const { gmTarget: nivelSemaforoFlexibilidade } = this.props.simulator2.nivelSemaforo;
    const filteredRows = rows.filter(x => !x.excluido && x.volume > 0);

    // const sumFinalPriceEncargo = filteredRows.reduce((sum, row) => {
    //   return sum + (row.subTotalPrice * row.volume);
    // }, 0);
    // const sumFinalPriceCpaMax = filteredRows.reduce((sum, row) => {
    //   return sum + (row.maxDscCpa * row.volume);
    // }, 0);
    // const cpaTarget = GetSafeNumber(sumFinalPriceCpaMax / sumFinalPriceEncargo) * 100;
    // const sumFinalPriceCpa = filteredRows.reduce((sum, row) => sum + ((row.volume) * row.brDscCpa), 0);
    // const cpaActual = GetSafeNumber(sumFinalPriceCpa / sumFinalPriceEncargo) * 100;
    // const score = cpaActual < cpaTarget ? (cpaActual / cpaTarget) : (Math.round(cpaActual) / Math.round(cpaTarget));
    // const scoreNegociacaoCpa = Number((GetSafeNumber(score) * 100).toFixed(2));

    const valorNegociado = GetSafeNumber(filteredRows.reduce((acc, x) => acc + x.netSales, 0));
    const gmTargetSeeds = GetSafeNumber(filteredRows.reduce((acc, x) => acc + x.netSales * (x.gmTarget / 100), 0)) / valorNegociado;
    const gmActualSeeds = GetSafeNumber(filteredRows.reduce((acc, x) => acc + x.gmStd, 0)) / valorNegociado;
    const score = GetSafeNumber(gmActualSeeds / gmTargetSeeds) * 100;

    const nivelFlexibilidade = nivelSemaforoFlexibilidade.find(x => {
      var ssMin = x.minimo;
      var vSsMin = ssMin == null || score >= ssMin;
      var ssMax = x.maximo;
      var vSsMax = ssMax == null || score < ssMax;

      return vSsMin && vSsMax;
    });

    const sumTotalScDsc = filteredRows.reduce((sum, row) => {
      return sum + GetSafeNumber(row.totalCpaScDsc);
    }, 0);
    const sumTotalScVolume = filteredRows.reduce((sum, row) => {
      return sum + GetSafeNumber(row.realVolume);
    }, 0);

    const cpaSc = sumTotalScDsc / sumTotalScVolume;
    return {
      cpaActual: cpaSc,
      cpaTarget: gmTargetSeeds,
      nivelFlexibilidade,
      scoreNegociacaoCpa: score
    }
  }

  render() {
    const sections = this.props.simuladorSeeds?.sections;
    const metodosPagamento = this.props.metodoPagamento?.allMetodos;

    return (
      <Stack direction={"column"} spacing={2} width={"100%"}>
        {
          sections && sections?.map((section, index) => {
            const { encargosDates } = this.processEncargos(section);
            const { cpaActual, nivelFlexibilidade } = this.calcScore(section.rows ?? []);
            const metodoPagamento = metodosPagamento.find(metodo => metodo.metodoPagamentoId === section["condicao"]);
            const total = section?.rows?.filter(x => !x.excluido && x.valorFinalNegociado > 0).reduce((acc, x) => acc + x.valorFinalNegociado, 0);

            return (
              <div key={index}>
                <Box >
                  <Stack direction={"row"} alignItems={"center"}>
                    {section.isTemporaly ?
                      <Select
                        placeholder="Selecione o Tipo"
                        onChange={(e) => this.props.actions.simuladorSeeds.handleCreateSection(section.id, e.target.value)}
                        sx={{ minWidth: 130, width: "100%" }}
                        size="small"
                      >
                        {this.props.simuladorSeeds?.sectionsTypes?.filter(type => !sections.some(section => section.cultura === type))
                          .map((type) => (
                            <MenuItem
                              key={type}
                              value={type}
                            >
                              {type}
                            </MenuItem>
                          ))}
                      </Select>
                      :
                      <>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: "100%" }}>
                          <Stack direction="row" alignItems="center">
                            <IconButton onClick={() => this.props.actions.simuladorSeeds.handleToggleSection(section.id)} >
                              {section.isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                            <Typography sx={{ width: "100%", fontWeight: "bolder" }} textAlign={"start"}>
                              Seção {index + 1} - {section.cultura}
                            </Typography>
                          </Stack>

                          <IconButton onClick={() => this.props.actions.simuladorSeeds.handleRemoveSection(section.id)}>
                            <DeleteIcon sx={{ color: "#000" }} />
                          </IconButton>
                        </Stack>
                      </>
                    }
                  </Stack>
                </Box>
                <Collapse in={section.isOpen} timeout="auto" unmountOnExit>
                  <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }}>
                    <Grid item xs={2} sm={8} md={12}>
                      {total > 0 &&
                        <Stack direction={"row"} gap={2} justifyContent={{ md: "flex-end", sm: "center", xs: "center" }} flexWrap={"wrap"}>
                          <div>
                            <label style={{ marginBottom: "14px" }}>Valor total</label>
                            <h4>
                              {
                                total.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: (section.campanha.currency === "BRL" ? "BRL" : "USD"),
                                })
                              }
                            </h4>
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <label style={{ marginBottom: "14px" }}>CPA - SEEDS</label>
                            <h4 >
                              {Number(cpaActual.toFixed(2)).toLocaleString("pt-BR")} / Saco
                            </h4>
                          </div>
                          <div>
                            <label style={{ marginBottom: "0" }}>Score </label>
                            <Semaforo cor={nivelFlexibilidade?.cor} />
                          </div>
                        </Stack>
                      }
                    </Grid>

                    <Grid item xs={2} sm={8} md={12} >
                      <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "2px", backgroundColor: "#e6e8ea", fontWeight: "bold" }}>
                        <Grid item xs={12} sx={{ paddingTop: "1rem !important" }}>
                          <p>Selecione os parâmetros da Simulação:</p>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2} sm={8} md={2}>
                      <label className="d-block">Campanha</label>
                      <Select
                        value={section["campanhaId"]}
                        placeholder="Campanha"
                        onChange={(e) => this.props.actions.simuladorSeeds.handleChangeCampaign(section.id, e.target.value, this.state.distrito, this.state.territorio, section.cultura)}
                        sx={{ minWidth: 130, width: "100%" }}
                        size="small"
                      >
                        {!this.props.campanhas.isLoadingSeeds &&
                          this.props.campanhas.seedsCampaigns
                            .filter(x => x.cultura === section.cultura)
                            .map((campanha) => (
                              <MenuItem
                                key={`${campanha.campaignId}_${campanha.salesOrg}`}
                                value={campanha.campaignId}
                              >
                                {campanha.groupedCampaign}
                              </MenuItem>
                            ))}
                      </Select>
                    </Grid>
                    <Grid item xs={2} sm={8} md={2}>
                      <label>Condição</label>
                      <Select
                        value={section["condicao"]}
                        placeholder="Condição"
                        onChange={(e) => this.handleChangePaymentTerm(section, e.target.value)}
                        sx={{ width: "100%" }}
                        size="small"
                      >
                        {metodosPagamento
                          .filter(x => x.campaignId === section?.campanhaId?.split("_")[0])
                          ?.map((condicao) => (
                            <MenuItem
                              key={condicao.metodoPagamentoId}
                              value={condicao.metodoPagamentoId}
                            >
                              {condicao.metodo}
                            </MenuItem>
                          ))}
                      </Select>
                    </Grid>
                    <Grid item xs={2} sm={8} md={2}>
                      <label>Vencimento</label>
                      <Select
                        value={section["vencimento"]}
                        placeholder="Vencimento"
                        onChange={(e) => this.handleChangePaymentDate(section, e.target.value)}
                        sx={{ width: "100%" }}
                        size="small"
                      >
                        {encargosDates.sort((a, b) => {
                          const dateA = toDateTimeFormat(a.conditionGroupDscp);
                          const dateB = toDateTimeFormat(b.conditionGroupDscp);

                          return dateA - dateB;
                        }).map((data) => (
                          <MenuItem key={data.conditionGroupId} value={data.conditionGroupDscp}>
                            {data.conditionGroupDscp}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    {metodoPagamento && metodoPagamento.isBarter && (
                      <Grid item xs={12} sm={8} md={1.5}>
                        <label>Valorização Barter</label>
                        <ErrorTooltip
                          name="Valorização Barter"
                          id="valorizacaoBarter"
                          value={Number(section["valorizacaoBarter"])}
                          onChange={(value) => this.handleChangeBarter(section.id, value)}
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={2}
                          customInput={TextField}
                          size="small"
                          suffix={"%"}
                          sx={{ input: { textAlign: "right" }, width: "100%" }}
                          maxValue={metodoPagamento.maxBarter}
                          messageError={`O valor precisa ser igual ou inferior a ${metodoPagamento.maxBarter}%`}
                          allowNegative={false}
                        />
                      </Grid>
                    )}
                    {metodoPagamento && metodoPagamento.isAdimplencia && (
                      <Grid item xs={12} sm={8} md={2}>
                        <label>Bônus de Adimplência</label>
                        <ErrorTooltip
                          name="Bônus de Adimplência"
                          id="bonusAdimplencia"
                          value={Number(section["bonusAdimplencia"])}
                          onChange={(value) => this.handleChangeAdimplencia(section.id, value)}
                          thousandSeparator="."
                          decimalSeparator=","
                          decimalScale={2}
                          customInput={TextField}
                          size="small"
                          suffix={"%"}
                          sx={{ input: { textAlign: "right" }, width: "100%" }}
                          maxValue={metodoPagamento.maxAdimplencia ?? 0}
                          messageError={`O valor precisa ser igual ou inferior a ${metodoPagamento.maxAdimplencia ?? 0}%`}
                          allowNegative={false}
                        />
                      </Grid>
                    )}
                    <Grid item xs={2} sm={8} md={2}>
                      <label style={{ opacity: 0 }}>SPACING</label>
                      <ConfirmationDialog
                        variant="outlined"
                        color={"azul"}
                        endIcon={<AddShoppingCartIcon />}
                        fullWidth
                        sx={{ minHeight: "40px" }}
                        options={
                          section?.produtos ?
                            section.produtos.map((produto) => { return { value: produto.productId, label: produto.product } }) :
                            []
                        }
                        onChange={(value) => this.handleChangeProduct(value, section)}
                      >
                        Adicionar Produto
                      </ConfirmationDialog>
                    </Grid>
                  </Grid>

                  <SimuladorTableSeeds section={section} state={this.state} actions={this.props.actions.simuladorSeeds} handleChangeSelect={this.handleChangeSelect} />
                </Collapse>
              </div>
            )
          })
        }

        {this.props.simuladorSeeds?.sectionsTypes?.length !== sections?.length && (
          <Button
            variant="outlined"
            onClick={() => this.props.actions.simuladorSeeds.handleTemporalySection()}
          >
            Adicionar Seção +
          </Button>
        )}

      </Stack>
    );
  }
}

export default connect(
  (state) => state,
  (dispatch) => {
    return {
      actions:
      {
        simuladorSeeds: bindActionCreators(simulatorSeedsActions, dispatch),
        simulador: bindActionCreators(simulator2Actions, dispatch),
        produtosSeeds: bindActionCreators(productActions, dispatch),
      }
    }
  }
)(SimulatorFormSeeds);
