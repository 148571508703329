import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Collapse, IconButton, MenuItem, Select, Tooltip } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import React, { Component } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { AllCampaignsActions } from "../../../../actions/allcampaign.actions";
import { clientActions } from "../../../../actions/client.actions";
import { desmembramentoActions } from "../../../../actions/desmembramento.actions";
import { nivelSemaforoActions } from "../../../../actions/nivelSemaforo.actions";
import { productActions } from "../../../../actions/products.actions";
import { simulator2Actions } from '../../../../actions/simulator2.actions';
import ResumeCp from './components/Cp/Resume';
import { TableClientes } from "./components/DesmembramentoCliente";
import { WatchNodeIsolate, zeroPad } from "./components/HelpersComponents";
import { TableProdutos } from "./components/SelecaoProduto";
import { classUseRedirect, isNullOrEmpty } from '../../../../helpers';
import { metodoPagamentoActions } from '../../../../actions/metodopagamento.actions';
import { PrintPage } from './components/PrintPage/DesmembramentoPrintPage';
import * as ReactDOMServer from 'react-dom/server';
import { userActions } from '../../../../actions/user.actions';

const validForm = (values, cenario) => {
  const formCp = values[0];
  const formSeeds = values[1];

  if (cenario?.isCpActive && cenario?.cenarioV2Cp && formCp === undefined) {
    return {
      isValid: false,
      error: "Nenhum desmembramento para Crop Protection foi criado."
    }
  }

  if (cenario?.isSeedsActive && !isNullOrEmpty(cenario?.cenarioV2Seeds) && isNullOrEmpty(formSeeds)) {
    return {
      isValid: false,
      error: "Nenhum desmembramento para Seeds foi criado."
    }
  }

  if (cenario?.isCpActive && cenario?.cenarioV2Cp && formCp !== undefined) {
    if (isNullOrEmpty(formCp?.orderType)) {
      return {
        isValid: false,
        error: "Campo Order Type em Crop Protection não foi preenchido."
      }
    }
    if (!isNullOrEmpty(cenario?.cenarioV2Cp?.itensCenarios)) {
      const rows = formCp.cenarioFormularioAccount;
      // console.log(rows);
      if (isNullOrEmpty(rows)) {
        return {
          isValid: false,
          error: "Nenhum cliente selecionado para Crop Protection."
        }
      }

      const values = cenario?.cenarioV2Cp?.itensCenarios.map((row, i) => {
        var total = rows.reduce((acc, x) =>
          acc + x.cenarioFormularioAccountItens
            .filter((y) => y.itensCenarioId === row.id)
            .reduce((sum, y) => sum + y.volume, 0)
          , 0
        );

        var per = (total / row.volume) * 100;
        per = (!isFinite(per) || isNaN(per)) ? 0 : per;
        return per;
      });

      if (values.some(x => x < 100)) {
        return {
          isValid: false,
          error: "Volume de Crop Protection não foi completamente desmembrado."
        }
      }

      if (rows.some(x => x.cenarioFormularioAccountItens.some(c => isNullOrEmpty(c.material)))) {
        return {
          isValid: false,
          error: "Selecione um material para todos os produtos de Crop Protection."
        }
      }

      if (rows.some(x => x.cenarioFormularioAccountItens.some(c => c.qtdPedido === 0))) {
        return {
          isValid: false,
          error: "Selecione um volume valido para todos os produtos de Crop Protection."
        }
      }

      if (rows.some(x => x.cenarioFormularioAccountItens.some(c => c.dataEntregaSol == null))) {
        return {
          isValid: false,
          error: "Preencha todas as datas de entrega para Crop Protection."
        }
      }
    }
  }

  if (cenario?.isSeedsActive && !isNullOrEmpty(cenario?.cenarioV2Seeds) && !isNullOrEmpty(formSeeds)) {
    for (var formSeed of formSeeds) {
      if (isNullOrEmpty(formSeed?.orderType)) {
        return {
          isValid: false,
          error: `Campo Order Type na cultura ${formSeed?.cultura} em Seeds não foi preenchido.`
        }
      }

      // eslint-disable-next-line no-loop-func
      const cenarioSeed = cenario?.cenarioV2Seeds.find(c => c.cultura === formSeed.cultura);

      if (!isNullOrEmpty(cenarioSeed)) {
        const rows = formSeed.cenarioFormularioAccount;
        // console.log(rows);
        if (isNullOrEmpty(rows)) {
          return {
            isValid: false,
            error: `Nenhum cliente selecionado para cultura ${formSeed?.cultura} em Seeds.`
          }
        }

        const values = cenarioSeed.rows.map((row, i) => {
          var total = rows.reduce((acc, x) =>
            acc + x.cenarioFormularioAccountItens
              .filter((y) => y.itensCenarioId === row.id)
              .reduce((sum, y) => sum + y.volume, 0)
            , 0
          );

          var per = (total / row.volume) * 100;
          per = (!isFinite(per) || isNaN(per)) ? 0 : per;
          return per;
        });

        if (values.some(x => x < 100)) {
          return {
            isValid: false,
            error: `Volume para cultura ${formSeed?.cultura} em Seeds não foi completamente desmembrado.`
          }
        }

        if (rows.some(x => x.cenarioFormularioAccountItens.some(c => isNullOrEmpty(c.material)))) {
          return {
            isValid: false,
            error: `Selecione um sufixo para todos os produtos da cultura ${formSeed?.cultura} em Seeds.`
          }
        }

        if (rows.some(x => x.cenarioFormularioAccountItens.some(c => c.dataEntregaSol == null))) {
          return {
            isValid: false,
            error: `Preencha todas as datas de entrega para cultura ${formSeed?.cultura} em Seeds.`
          }
        }
      }
    }
  }

  return {
    isValid: true,
    error: ""
  }
}

function Form({ role, cliente = {}, cenario = {}, formulario = {}, moeda = "USD", allMetodos = [], actions = {}, isLoadingDownload = false, situacaoEspecial = [], municipios = [], saveFormulario = (formulario = {}, status = 0) => { }, sendFormulario = (formulario = {}) => { }, metodoPagamentoCpItem }) {
  const [open, setOpen] = React.useState(true);
  const [save, setSave] = React.useState(false);
  const [finish, setFinish] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  const { setValue, handleSubmit, getValues, control } = useForm({
    defaultValues: formulario
  });

  const onSubmit = (values) => {
    if (submitted)
      return;

    setSubmitted(true);

    if (save) {
      saveFormulario(values, 0);
      setSave(false)
    } else if (finish) {
      saveFormulario(values, 1);
      setFinish(false);
    }
  }

  return (
    <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }}
      component={"form"}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}>
      <Grid item xs={2} sm={8} md={12} sx={{ alignSelf: "flex-end", marginTop: "18px" }}>
        <Stack direction={{ xs: "column", sm: "column", md: "row" }} gap={2} justifyContent={"center"}>
          <Button
            fullWidth
            type='submit'
            onClick={() => setSave(true)}
            variant="contained"
            color="laranja"
            sx={{ padding: "6.5px 16px", height: "100%", minHeight: "40px" }}
          >
            Salvar Desmembramento
          </Button>
          {role?.canSendDismemberment && (
            <WatchNodeIsolate
              control={control}
              valueToWatch={["formularioCp", "formularioSeeds"]}
              Node={React.Fragment}
              textDisplay={(values) => {
                var validation = validForm(values, cenario);
                return <>
                  {validation.isValid ? <>
                    <Button
                      fullWidth
                      type='submit'
                      onClick={() => setFinish(true)}
                      variant="contained"
                      color="verde"
                      sx={{ padding: "6.5px 16px", height: "100%", minHeight: "40px" }}
                    >
                      Enviar Desmembramento
                    </Button>
                  </> : <>
                    <Tooltip title={validation.error}>
                      <span style={{ width: "100%" }}>
                        <Button
                          fullWidth
                          disabled={true}
                          variant="contained"
                          color="verde"
                          sx={{ padding: "6.5px 16px", height: "100%", minHeight: "40px" }}
                        >
                          Enviar Desmembramento
                        </Button>
                      </span>
                    </Tooltip>
                  </>
                  }
                </>
              }}
            />
          )}
        </Stack>
      </Grid>
      {cenario.isCpActive && cenario?.cenarioV2Cp && formulario.formularioCp &&
        <>
          <Grid item xs={2} sm={8} md={12} >
            <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "18px", color: "white", backgroundColor: "var(--cort-blue)", fontWeight: "bold" }}>
              <Grid item xs={12} sx={{ paddingTop: "1rem !important" }} >
                <p>CROP PROTECTION</p>
              </Grid>
            </Grid>
          </Grid>

          <ResumeCp cenario={cenario?.cenarioV2Cp} metodoPagamento={metodoPagamentoCpItem} />

          {/*FORMULÁRIO ----- INICIO*/}

          <Grid item xs={2} sm={8} md={6} className="align-self-center">
            <h5>
              <b>Order Type:</b>
            </h5>
            <Controller
              control={control}
              name={`formularioCp.orderType`}
              render={({ field }) => {
                return (<Select
                  fullWidth
                  size="small"
                  {...field}
                >
                  {
                    situacaoEspecial.filter(x => x.business === "Crop").map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))
                  }
                </Select>)
              }
              }
            />
          </Grid>
          <Grid item xs={2} sm={8} md={6} className="align-self-center">
            <h5>
              <b>PO Number:</b>
            </h5>
            <Controller
              render={({ field }) =>
                <TextField {...field}
                  color={'azul'}
                  fullWidth
                  size="small"
                />
              }
              name="formularioCp.poNumber"
              control={control}
            />
          </Grid>
          <Grid item xs={2} sm={8} md={12} className="align-self-center">
            <h5>
              <b>Instruções CSR: </b>
            </h5>
            <Controller
              render={({ field }) =>
                <TextField {...field}
                  color={'azul'}
                  fullWidth
                  multiline
                  size="small"
                />
              }
              name="formularioCp.instrucoesCsr"
              control={control}
            />
          </Grid>

          {/*FORMULÁRIO ----- FIM*/}

          {/*TABELAS ----- INICIO*/}
          <Grid item xs={2} sm={8} md={12} >
            <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "18px", color: "white", backgroundColor: "var(--cort-blue)", fontWeight: "bold" }}>
              <Grid item xs={12} sx={{ paddingTop: "calc(1rem - 8px) !important" }} >
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon color="whiteB" /> : <KeyboardArrowDownIcon color="whiteB" />}
                </IconButton>
                <p style={{ display: "inline-flex", marginLeft: "8px" }}>Produtos do cenário</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} sm={8} md={12} >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <TableProdutos
                rows={cenario?.cenarioV2Cp?.itensCenarios}
                currency={moeda}
              />
            </Collapse>
          </Grid>
          <Grid item xs={2} sm={8} md={12} >
            <TableClientes
              rows={formulario?.formularioCp?.cenarioFormularioAccountBase}
              itens={cenario?.cenarioV2Cp?.itensCenarios}
              currency={moeda}
              control={control}
              setValue={setValue}
              getValues={getValues}
              situacaoEspecial={situacaoEspecial}
            />
          </Grid>
          {/*TABELAS ----- FIM*/}
        </>
      }
      {cenario.isSeedsActive && !isNullOrEmpty(cenario?.cenarioV2Seeds) && !isNullOrEmpty(formulario.formularioSeeds) &&
        <>
          <Grid item xs={2} sm={8} md={12} >
            <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "18px", color: "white", backgroundColor: "var(--cort-blue)", fontWeight: "bold" }}>
              <Grid item xs={12} sx={{ paddingTop: "1rem !important" }} >
                <p>SEEDS</p>
              </Grid>
            </Grid>
          </Grid>

          {/* <ResumeCp cenario={cenario?.cenarioV2Cp} metodoPagamento={metodoPagamentoCpItem} /> */}
          {formulario.formularioSeeds.map((x, i) => {
            const cenarioSeed = cenario?.cenarioV2Seeds.find(c => c.cultura == x.cultura)
            const metodoPagamentoSeedsItem = allMetodos.find(metodo => metodo.metodoPagamentoId === cenarioSeed.condicao);
            // console.log(cenarioSeed)
            return (
              <React.Fragment key={cenarioSeed.id}>
                <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: 2, width: "calc(100%) !important", marginTop: "18px", backgroundColor: "#e6e8ea", fontWeight: "bold" }}>
                  <Grid item xs={12} sx={{ paddingTop: "1rem !important" }}>
                    <p>{x.cultura}</p>
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={8} md={2.5}>
                  <label className="d-block">Campanha</label>
                  <h5>{cenarioSeed.campanhaGroup}</h5>
                </Grid>
                <Grid item xs={2} sm={8} md={2.5}>
                  <label className="d-block">Condição</label>
                  <h5>{metodoPagamentoSeedsItem?.metodo}</h5>
                </Grid>
                {metodoPagamentoSeedsItem?.isBarter && (
                  <Grid item xs={2} sm={8} md={2.5}>
                    <label className="d-block">Valorização Barter</label>
                    <h5>{cenarioSeed.valorizacaoBarter || ''}</h5>
                  </Grid>
                )}
                {metodoPagamentoSeedsItem?.isBonusAdimplencia && (
                  <Grid item xs={2} sm={8} md={2.5}>
                    <label className="d-block">Bônus Adimplencia</label>
                    <h5>{cenarioSeed.bonusAdimplencia || ''}</h5>
                  </Grid>
                )}
                <Grid item xs={2} sm={8} md={2.5}>
                  <label className="d-block">Vencimento</label>
                  <h5>{cenarioSeed.vencimento}</h5>
                </Grid>
                {/*FORMULÁRIO ----- INICIO*/}
                <Grid item xs={2} sm={8} md={6} className="align-self-center">
                  <h5>
                    <b>Order Type:</b>
                  </h5>
                  <Controller
                    control={control}
                    name={`formularioSeeds[${i}].orderType`}
                    render={({ field }) => {
                      return (<Select
                        fullWidth
                        size="small"
                        {...field}
                      >
                        {
                          situacaoEspecial.filter(x => x.business === "Seed").map((option) => (
                            <MenuItem key={option.id} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))
                        }
                      </Select>)
                    }
                    }
                  />
                </Grid>
                <Grid item xs={2} sm={8} md={6} className="align-self-center">
                  <h5>
                    <b>PO Number:</b>
                  </h5>
                  <Controller
                    render={({ field }) =>
                      <TextField {...field}
                        color={'azul'}
                        fullWidth
                        size="small"
                      />
                    }
                    name={`formularioSeeds[${i}].poNumber`}
                    control={control}
                  />
                </Grid>
                <Grid item xs={2} sm={8} md={12} className="align-self-center">
                  <h5>
                    <b>Instruções CSR: </b>
                  </h5>
                  <Controller
                    render={({ field }) =>
                      <TextField {...field}
                        color={'azul'}
                        fullWidth
                        multiline
                        size="small"
                      />
                    }
                    name={`formularioSeeds[${i}].instrucoesCsr`}
                    control={control}
                  />
                </Grid>
                {/*FORMULÁRIO ----- FIM*/}

                {/*TABELAS ----- INICIO*/}
                <Grid item xs={2} sm={8} md={12} >
                  <Grid container spacing={2} className="rounded-3" sx={{ marginLeft: "0 !important", width: "calc(100%) !important", marginTop: "18px", color: "white", backgroundColor: "var(--cort-blue)", fontWeight: "bold" }}>
                    <Grid item xs={12} sx={{ paddingTop: "calc(1rem - 8px) !important" }} >
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                      >
                        {open ? <KeyboardArrowUpIcon color="whiteB" /> : <KeyboardArrowDownIcon color="whiteB" />}
                      </IconButton>
                      <p style={{ display: "inline-flex", marginLeft: "8px" }}>Produtos do cenário</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={8} md={12} >
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <TableProdutos
                      rows={cenarioSeed.rows}
                      currency={cenarioSeed.moeda}
                      isSeeds={true}
                    />
                  </Collapse>
                </Grid>
                <Grid item xs={2} sm={8} md={12} >
                  <TableClientes
                    rows={x.cenarioFormularioAccountBase}
                    itens={cenarioSeed.rows}
                    isSeeds={true}
                    metodoPagamento={metodoPagamentoSeedsItem?.metodo}
                    currency={moeda}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    situacaoEspecial={situacaoEspecial}
                    formulario={`formularioSeeds[${i}]`}
                  />
                </Grid>
                {/*TABELAS ----- FIM*/}
              </React.Fragment>
            )
          })}

        </>
      }
    </Grid >
  );
}

class DesmembramentoPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientId: "",
      cenarioId: 0
    };

    this.sendFormulario = this.sendFormulario.bind(this);
    this.saveFormulario = this.saveFormulario.bind(this);
  }

  sendFormulario(formulario = {}) {
    const cenario = this.props.dmb.cenario;
    const htmlString = ReactDOMServer.renderToString(
      <PrintPage
        cliente={this.props.simulator2.clientes?.client}
        moeda={cenario?.moeda}
        cenario={cenario}
        formulario={formulario}
        allMetodos={this.props.metodoPagamento.allMetodos}
        metodoPagamentoCp={this.props.metodoPagamento.metodo}
        situacaoEspecial={this.props.dmb.situacaoEspecial}
      />
    );

    this.props.actions.saveFormulario(formulario, htmlString);
  }

  saveFormulario(formulario = {}, status) {
    this.props.actions.PostFormulario(this.state.cenarioId, formulario, status);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dmb.isLoading !== this.props.dmb.isLoading && this.props.dmb.sendSuccess) {
      const formulario = this.props.dmb.formulario;
      if (formulario.formularioStatus === 1) {
        this.sendFormulario(formulario);
      }
      else if (formulario.formularioStatus === 2) {
        this.props.redirect(`/Simulador2/MinhasSimulacoesCliente?GroupId=${this.state.clientId}`);
      }
    }
  }

  componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    this.setState({ clientId: params.GroupId });
    this.setState({ cenarioId: params.CenarioId });
    this.props.actions.requestRole();
    this.props.actions.GetCenario(params.CenarioId);
    this.props.actions.requestFormulario(params.CenarioId);
    this.props.actions.requestClient(params.GroupId);
    this.props.actions.requestOrderTypes();
    this.props.actions.requestAllMetodos();
  }

  componentWillUnmount() {
    this.props.actions.clearFormDmb();
  }

  render() {
    const { client } = this.props.simulator2.clientes;
    const cenario = this.props.dmb.cenario;
    const moeda = cenario?.moeda ?? "USD"

    console.log(this.props);

    return (
      <div className="card">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.props.dmb.cenarioStatus === "loading" || this.props.simulator2.clientes.isLoadingClient || this.props.metodoPagamento.isLoading || this.props.dmb.isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card-header" style={{ padding: "var(--app-card-header-padding)" }}>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            sx={{
              "* a": {
                color: "var(--cort-blue) !important"
              }
            }}>
            <Link to={`/Simulador2/MinhasSimulacoesCliente?GroupId=${this.state.clientId}`}>
              <ArrowBackIcon />
            </Link>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" to={"/"}>
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={"/Simulador2"}
              >
                Simulador 2.0
              </Link>
              <Link
                underline="hover"
                color="inherit"
                to={`/Simulador2/MinhasSimulacoesCliente?GroupId=${this.state.clientId}`}
              >
                Minhas Simulações
              </Link>
              <Typography color="text.primary">Desmembramento</Typography>
            </Breadcrumbs>
          </Stack>
        </div>
        <div className="card-body" style={{ padding: "var(--app-card-padding)" }}>
          <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 2, sm: 8, md: 12 }}>
            <Grid item xs={2} sm={8} md={12}>
              <Stack
                justifyContent="flex-start"
                alignItems="start"
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                sx={{
                  [`& h5`]: {
                    fontWeight: "bold",
                    marginBottom: 0
                  },
                  mb: "18px"
                }}
              >
                <h5>Simulação #{zeroPad(cenario.id, 4)}</h5>
                <h5>Distrito: {client.distrito}</h5>
                <h5>Território: {client.territorio}</h5>
              </Stack>
            </Grid>
            <Grid item xs={2} sm={4} md={2.4} >
              <h5>
                <b>Cliente: </b>
              </h5>
            </Grid>
            <Grid item xs={2} sm={4} md={3.6}>
              <h5>
                {client.nomeVisaoUnica}
              </h5>
            </Grid>
            <Grid item xs={2} sm={4} md={2.4}>
              <h5>
                <b>Valor total Simulação: </b>
              </h5>
            </Grid>
            <Grid item xs={2} sm={4} md={3.6}>
              <h5>
                {cenario.totalSimulacao?.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: moeda === "BRL" ? "BRL" : "USD",
                })}
              </h5>
            </Grid>
          </Grid>
          {!this.props.dmb.isLoading &&
            <Form
              role={this.props.user?.role?.role}
              cliente={client}
              actions={this.props.actions}
              moeda={moeda}
              cenario={cenario}
              isLoadingDownload={this.props.formulario.isLoadingDownload}
              formulario={this.props.dmb.formulario}
              situacaoEspecial={this.props.dmb.situacaoEspecial}
              municipios={this.props.clientes.municipios}
              sendFormulario={this.sendFormulario}
              metodoPagamentoCpItem={this.props.metodoPagamento.metodo}
              allMetodos={this.props.metodoPagamento.allMetodos}
              saveFormulario={this.saveFormulario}
            />
          }
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => {
    return {
      formulario: state.formulario,
      dmb: state.dmb,
      clientes: state.clientes,
      simulator2: state.simulator2,
      metodoPagamento: state.metodoPagamento,
      user: state.user
    }
  },
  (dispatch) => {
    return {
      actions: bindActionCreators(
        {
          ...userActions,
          ...productActions,
          ...simulator2Actions,
          ...clientActions,
          ...AllCampaignsActions,
          ...nivelSemaforoActions,
          ...metodoPagamentoActions,
          ...desmembramentoActions
        },
        dispatch,
      ),
    };
  }
)(classUseRedirect(DesmembramentoPage));
