import React, { useState } from 'react';
import {
  Button, Table, TableBody, TableCell,
  TableHead, TableRow, IconButton,
  Alert as MuiAlert, TextField, Dialog,
  TableContainer, DialogActions, Paper,
  DialogContentText, DialogContent, DialogTitle,
  Typography,
  Stack
}
  from '@mui/material';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Virtualize from "../../../AutocompleteVirtual";
import { NumericFormat } from "react-number-format";
import DeleteIcon from "@mui/icons-material/Delete";
import CircleIcon from '@mui/icons-material/Circle';
import ErrorIcon from '@mui/icons-material/Error';
import DiscountDialog from './DiscountDialog';
import { isNullOrEmpty } from '../../../../helpers';

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          height: "30px",
          fontSize: "0.775rem",
          minWidth: "90px",
          padding: "6px 6px",
          "&:first-of-type": {
            padding: "6px 6px 6px 16px",
          },
          "&:last-child": {
            padding: "6px 16px 6px 6px",
            minWidth: "fit-content"
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          fontSize: "0.775rem",
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "0.775rem",
        },
      }
    }
  }
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    border: 'none',
  },
}));

const CellInputAlert = ({ messageError, maxValue, ...props }) => {
  const [open, setOpen] = React.useState(false);

  const isAllowed = (value, max) => {
    var number = Number(value.value)

    if (max === undefined)
      return true;

    if (number > max) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    return number <= max;
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (<HtmlTooltip placement="right" leaveDelay={600} open={open} onClose={handleClose}
    title={
      <React.Fragment>
        <Alert severity="error">
          {messageError}
        </Alert>
      </React.Fragment>
    }
  >
    <TableCell align="center">
      <NumericFormat
        {...props}
        isAllowed={(values, sourceInfo) => isAllowed(values, maxValue)}
      />
    </TableCell>
  </HtmlTooltip>
  );
}

const tableCells = [
  {
    id: 'produto',
    label: 'Produto',
    align: 'left',
    headStyle: { position: "sticky", left: "0px", zIndex: 20, backgroundColor: "#fff", borderRight: "1px solid #e0e0e0" },
    rowStyle: { position: "sticky", left: "0px", zIndex: 10, backgroundColor: "#fff", borderRight: "1px solid #e0e0e0" },
  },
  { id: 'ultimaNegociacao', label: 'Última Negociação', type: 'monetary', align: 'right' },
  { id: 'recomendacoes', label: 'Recomendações' },
  { id: 'salesInput', label: 'Sales Input (vol)', type: 'numeric' },
  { id: 'cota', label: 'Cota (vol)', type: 'numeric' },
  {
    id: 'sufixo',
    label: 'Sufixo',
    align: 'left',
    type: 'select',
    optionData: 'sufixos',
    optionLabel: 'sufixo',
    isInput: true,
    headStyle: { minWidth: "120px" }
  },
  {
    id: 'salesUnit',
    label: 'Sales Unit',
    align: 'left'
  },
  { id: 'precoSemente', label: 'Preço Semente', type: 'monetary', align: 'right' },
  { id: 'precoSt', label: 'Preço ST', type: 'monetary' },
  {
    id: 'encargo',
    label: 'Encargo',
    type: 'percent',
  },
  {
    id: 'brDscCtgCliMax',
    label: 'BR Desc.Categ.Cli Máximo',
    type: 'percent',
    showDiscount: true,
    disableToCultures: ['Milho', 'Soja']
  },
  {
    id: 'brDscCtgCli',
    label: 'BR Desc.Categ.Cli',
    type: 'percent',
    isInput: true,
    showDiscount: true,
    errorMessage: "Desconto acima do permitido!",
    disableToCultures: ['Milho', 'Soja']
  },
  {
    id: 'brDscCtgCliTrMax',
    label: 'BR Desc.Categ.Cli.Tr Máximo',
    type: 'percent',
    showDiscount: true,
    disableToCultures: ['Milho', 'Soja']
  },
  {
    id: 'brDscCtgCliTr',
    label: 'BR Desc.Categ.Cli. Tratamento',
    type: 'percent',
    showDiscount: true,
    isInput: true,
    disableToCultures: ['Milho', 'Soja']
  },
  {
    id: 'brDscPreCampMax',
    label: 'BR Desc. Pré-Camp Max',
    type: 'percent',
    showDiscount: true,
    errorMessage: "Desconto acima do permitido!"
  },
  {
    id: 'brDscPreCamp',
    label: 'BR Desc. Pré-Camp',
    type: 'percent',
    showDiscount: true,
    isInput: true,
    errorMessage: "Desconto acima do permitido!"
  },
  {
    id: 'brDscPreCvTrMax',
    label: 'BR Desc. Pré-CV Tratamento Max',
    type: 'percent',
    showDiscount: true,
    disableToCultures: ['Milho'],
    errorMessage: "Desconto acima do permitido!"
  },
  {
    id: 'brDscPreCvTr',
    label: 'BR Desc. Pré-CV Tratamento',
    type: 'percent',
    showDiscount: true,
    isInput: true,
    disableToCultures: ['Milho'],
    errorMessage: "Desconto acima do permitido!"
  },
  {
    id: 'brDscPenMax',
    label: 'BR Desc. Peneira Max',
    type: 'percent',
    errorMessage: "Desconto acima do permitido!",
    hidden: true,
  },
  {
    id: 'brDscPen',
    label: 'BR Desc. Peneira',
    type: 'percent',
    isInput: true,
    errorMessage: "Desconto acima do permitido!",
    hidden: true,
  },
  {
    id: 'brDscTrMax',
    label: 'BR Desc. Tratamento Max',
    type: 'percent',
    showDiscount: true,
    disableToCultures: ['Milho'],
    errorMessage: "Desconto acima do permitido!"
  },
  {
    id: 'brDscTr',
    label: 'BR Desc. Tratamento',
    type: 'percent',
    showDiscount: true,
    isInput: true,
    disableToCultures: ['Milho'],
    errorMessage: "Desconto acima do permitido!"
  },
  {
    id: 'brDscVolMax',
    label: 'BR Desc. sobre Vol Max',
    type: 'percent',
    showDiscount: true,
    errorMessage: "Desconto acima do permitido!"
  },
  {
    id: 'brDscVol',
    label: 'BR Desc. sobre Vol',
    type: 'percent',
    showDiscount: true,
    isInput: true,
    errorMessage: "Desconto acima do permitido!"
  },
  {
    id: 'brDscResCpMax',
    label: 'BR Desc. RES/Cooperante Max',
    type: 'percent',
    showDiscount: true,
    // enableToClientType: ['COOP'],
  },
  {
    id: 'brDscResCp',
    label: 'BR Desc. RES/Cooperante',
    type: 'percent',
    showDiscount: true,
    isInput: true,
    // enableToClientType: ['COOP'],
    errorMessage: "Desconto acima do permitido!"
  },
  {
    id: 'brDscDirMax',
    label: 'BR Desc. Diretoria Max',
    type: 'percent',
    hidden: true,
  },
  {
    id: 'brDscDir',
    label: 'BR Desc. Diretoria',
    type: 'percent',
    isInput: true,
    errorMessage: "Desconto acima do permitido!",
    hidden: true,
  },
  {
    id: 'dscSobDsmpMax',
    label: 'Sob Desempenho Max',
    type: 'percent',
    hidden: true
  },
  {
    id: 'dscSobDsmp',
    label: 'Sob Desempenho',
    type: 'percent',
    isInput: true,
    errorMessage: "Desconto acima do permitido!",
    hidden: true
  },
  {
    id: 'dscGestInvMax',
    label: 'Gestão de Inventário Max',
    type: 'percent',
    hidden: true,
  },
  {
    id: 'dscGestInv',
    label: 'Gestão de Inventário',
    type: 'percent',
    isInput: true,
    errorMessage: "Desconto acima do permitido!",
    hidden: true,
  },
  {
    id: 'brDscVexMax',
    label: 'BR Desc. Verba Exc Max',
    type: 'percent',
    hidden: true,
  },
  {
    id: 'brDscVex',
    label: 'BR Desc. Verba Exc',
    type: 'percent',
    isInput: true,
    errorMessage: "Desconto acima do permitido!",
    hidden: true,
  },
  {
    id: 'brDscTgMax',
    label: 'BR Desc. Troca Grãos Max',
    type: 'percent',
    showDiscount: true,
  },
  {
    id: 'brDscTg',
    label: 'BR Desc. Troca Grãos',
    type: 'percent',
    showDiscount: true,
    isInput: true,
    errorMessage: "Desconto acima do permitido!",
  },
  { id: 'precoCorteva', label: 'Preço Corteva', type: 'monetary' },
  { id: 'precoCliente', label: 'Preço p.p Cliente (Opcional)', type: 'monetary', isInput: true },
  { id: 'volume', label: 'Volume', type: 'numeric', isInput: true, notAllowDecimal: true },
  {
    id: 'precoNegociado',
    label: 'Preço Negociado',
    type: 'monetary',
    isInput: true,
    errorMessage: "Preço negociado acima do valor final Corteva!"
  },
  {
    id: 'brDscCpa',
    label: 'BR Desc. CPA',
    type: 'monetary'
  },
  { id: 'valorFinalCorteva', label: 'Valor Final Corteva', type: 'monetary' },
  { id: 'valorFinalNegociado', label: 'Valor Final', type: 'monetary' }
  // { id: 'frb', label: 'FRB', type: 'numeric', hidden: true },
  // { id: 'cur', label: 'CUR', type: 'numeric', hidden: true },
  // { id: 'salesDeductionPer', label: 'Sales Deduction', type: 'percent', hidden: true },
  // { id: 'cogsSeeds', label: 'COGS SEMENTE (BRL)', type: 'monetary', hidden: true },
  // { id: 'cogsStPer', label: 'COGS ST (BRL)', type: 'percent', hidden: true },
  // { id: 'gmTarget', label: 'GM Target', type: 'percent', hidden: true },
  // { id: 'maxDscCpa', label: 'CPA Máx Aprovado ($)', type: 'monetary', hidden: true },
  // { id: 'salesDeduction', label: 'Sales Deduction', type: 'monetary', hidden: true },
  // { id: 'netSales', label: 'Net Sales', type: 'monetary', hidden: true },
  // { id: 'cogs', label: 'COGS', type: 'monetary', hidden: true },
  // { id: 'cogsSt', label: 'COGS ST (BRL)', type: 'monetary', hidden: true },
  // { id: 'gmStd', label: 'GM (STD)', type: 'monetary', hidden: true },
  // { id: 'gmPer', label: '%GM', type: 'percent', hidden: true },
  // { id: 'perAtingidoGm', label: '% Atingido GM', type: 'percent', hidden: true },
  // { id: 'perAtingidoCpa', label: '% Atingido CPA', type: 'percent', hidden: true },
  // { id: 'scoreGM', label: 'Score GM', type: 'score', hidden: true },
  // { id: 'scoreProduto', label: 'Score do Produto', type: 'score', hidden: true },
];

const GetDiscontsCollumns = (clientType, cultura) =>
  tableCells
    .filter(x =>
      (x.hidden ? !x.hidden : true) &&
      (x.disabled ? !x.disabled : true) &&
      (x.disableToCultures ? !x.disableToCultures.includes(cultura) : true) &&
      (x.enableToClientType ? x.enableToClientType.includes(clientType) : true) &&
      (!isNullOrEmpty(x.showDiscount) && x.showDiscount) &&
      (!isNullOrEmpty(x.isInput) && x.isInput))
    .map(x => { return { id: x.id, label: x.label } });

export default function SimuladorTableSeeds(props) {
  const { section, state, actions, handleChangeSelect } = props
  const [openDialog, setOpenDialog] = React.useState(false);
  const [motivo, setMotivo] = React.useState("");
  const [row, setRow] = React.useState("");
  const [sectionId] = React.useState(0);
  const [openDiscountDialog, setOpenDiscountDialog] = useState(false);
  const [sessionDiscounts, setSessionDiscounts] = useState(GetDiscontsCollumns(state.client?.type, section.cultura));
  const [activeDiscounts, setActiveDiscounts] = useState({});

  React.useEffect(() => {
    setSessionDiscounts(GetDiscontsCollumns(state.client?.type, section.cultura));
    setActiveDiscounts({});
  }, [section.cultura, state.client?.type]);

  const handleChangeMotivo = (e) => {
    setMotivo(e.target.value);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setMotivo("");
    setRow("");
  };

  const handleRemove = () => {
    if (motivo?.replaceAll(" ", "")?.length >= 20) {
      actions.removeRow(sectionId, row.id, motivo, section.condicao);
      setOpenDialog(false);
      setMotivo("");
      setRow("");
    }
  };

  const handleOpenDiscountDialog = () => {
    setOpenDiscountDialog(true);
  };

  const handleCloseDiscountDialog = () => {
    setOpenDiscountDialog(false);
  };

  const handleActiveDiscountChange = (updatedDiscounts) => {
    setActiveDiscounts(updatedDiscounts);
  };


  return (
    <>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Deseja Realmente remover?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Escreva o motivo da remoção do produto <b>{row?.produto}</b> recomendado para o Cliente:
          </DialogContentText>
          <TextField
            autoFocus
            value={motivo}
            onChange={handleChangeMotivo}
            margin="dense"
            id="name"
            label="Justificativa*"
            fullWidth
            variant="standard"
            error={motivo.length > 0 ? motivo?.replaceAll(" ", "").length < 20 : ""}
            helperText={motivo.length > 0 ? motivo?.replaceAll(" ", "").length < 20 ? `Quantidade de Caracteres: ${motivo?.replaceAll(" ", "").length} de 20` : "" : ""}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button onClick={handleRemove}>Remover</Button>
        </DialogActions>
      </Dialog>
      <DiscountDialog
        open={openDiscountDialog}
        close={handleCloseDiscountDialog}
        onActiveDiscountChange={handleActiveDiscountChange}
        sessionDiscounts={sessionDiscounts}
        activeDiscounts={activeDiscounts}
      />

      <Stack direction={"row"} alignItems={"center"} gap={2} mt={2}>
        <Typography>Descontos exibidos:</Typography>
        <Button variant="outlined" color="primary" onClick={handleOpenDiscountDialog}>
          Configurar
        </Button>
      </Stack>

      <TableContainer component={Paper} sx={{ mt: "1rem" }}>
        <ThemeProvider theme={(mainTheme) => createTheme({ ...mainTheme, ...theme, palette: { ...mainTheme.palette } })}>
          <Table stickyHeader sx={{ minWidth: 650, '* th': { textWrap: 'nowrap', fontWeight: "bold" } }} size="small">
            <TableHead sx={{ fontWeight: "bold" }}>
              <TableRow>
                {tableCells
                  .filter(x =>
                    (x.hidden ? !x.hidden : true) &&
                    (x.disabled ? !x.disabled : true) &&
                    (x.disableToCultures ? !x.disableToCultures.includes(section.cultura) : true) &&
                    (x.enableToClientType ? x.enableToClientType.includes(state.client?.type) : true) &&
                    (isNullOrEmpty(x.showDiscount) || (x.showDiscount && activeDiscounts[x.id]))
                  )
                  .map((head, index) => {
                    return (
                      <TableCell
                        key={head.id + head.label + "head" + index}
                        name={head.id + head.label + "head" + index}
                        align={head.align ?? 'center'}
                        sx={{ ...head.headStyle }}
                      >
                        {head.label}
                      </TableCell>
                    )
                  })}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ maxHeight: 400 }}>
              {section.rows && section.rows
                .filter(x => !x.excluido)
                .map((row, index) => (
                  <TableRow>
                    {tableCells
                      .filter(x =>
                        (x.hidden ? !x.hidden : true) &&
                        (x.disabled ? !x.disabled : true) &&
                        (x.disableToCultures ? !x.disableToCultures.includes(section.cultura) : true) &&
                        (x.enableToClientType ? x.enableToClientType.includes(state.client?.type) : true) &&
                        (isNullOrEmpty(x.showDiscount) || (x.showDiscount && activeDiscounts[x.id]))
                      )
                      .map((head) => {
                        let cellValue = row[head.id] ?? "";

                        if (!head.isInput) {
                          switch (head.type) {
                            case 'numeric':
                              cellValue = cellValue ? cellValue : "0";
                              cellValue = cellValue.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                              break;
                            case 'percent':
                              cellValue = cellValue ? cellValue : "0";
                              cellValue = cellValue.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "%";
                              break;
                            case 'monetary':
                              cellValue = cellValue ? cellValue : "0";
                              cellValue = cellValue.toLocaleString("pt-BR", {
                                style: "currency",
                                currency: section.moeda,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              });
                              break;
                            default:
                              cellValue = cellValue ? cellValue : "--";
                              break;
                          }
                        }
                        else {
                          cellValue = cellValue ? cellValue : 0;
                        }

                        return (
                          head.isInput ?
                            head.type === 'select' ?
                              (
                                <TableCell
                                  key={head.id + head.label + index}
                                  name={head.id + head.label + index}
                                  align={head.align ?? 'center'}
                                  sx={{ ...head.rowStyle, whiteSpace: "nowrap" }}
                                >
                                  <Virtualize
                                    sx={{
                                      color: "black",
                                      width: "120px",
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      'input': {
                                        fontSize: "clamp(0.5rem, 2rem)",
                                        fontFamily: "Gilroy",
                                        fontWeight: "bold",
                                        maxWidth: "80%"
                                      },
                                      '*, ::after, ::before': {
                                        border: "none !important",
                                        padding: "0px !important"
                                      },
                                    }}
                                    value={
                                      row[head.id] == null ?
                                        row[head.optionData]?.find((x) => x[head.optionLabel] === row[head.optionLabel]) ?? null :
                                        row[head.id]
                                    }
                                    options={row[head.optionData]}
                                    noOptionsText="Nenhum resultado encontrado..."
                                    getOptionLabel={(option) => option[head.optionLabel]}
                                    getValue={(option) => option[head.optionLabel]}
                                    onChange={(_, value) => handleChangeSelect(section.id, row.id, head.id, value)}
                                    disableClearable={true}
                                    renderInput={(params) => (
                                      <TextField {...params} placeholder={head.label} />
                                    )}
                                  ></Virtualize>
                                </TableCell>
                              ) :
                              (
                                <CellInputAlert
                                  key={head.id + head.label + index}
                                  name={head.id + head.label + index}
                                  value={cellValue}
                                  onValueChange={(e, _) => actions.handleChangeValue(section.id, row.id, head.id, Number(e.value), props.state.groupId, section.condicao, props.state.distrito, props.state.classificacaoSeeds)}
                                  customInput={TextField}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  decimalScale={head.notAllowDecimal !== undefined ? 0 : 2}
                                  size="small"
                                  suffix={head.type === 'percent' ? "%" : ""}
                                  sx={{ ...head.rowStyle, input: { textAlign: "right", minWidth: "120px" } }}
                                  maxValue={row[head.id + "Max"] !== undefined ? Math.abs(row[head.id + "Max"]) : undefined}
                                  messageError={head.errorMessage}
                                  allowNegative={false}
                                  InputProps={{
                                    startAdornment: <> {row[head.id] > Math.abs(row[head.id + "Max"]) && <Tooltip title={head.errorMessage}><ErrorIcon color="laranja" /></Tooltip>}</>
                                  }}
                                />
                              )
                            :
                            head.type === 'popup' ?
                              (
                                <TableCell key={head.id + head.label + index} align={head.align ?? 'center'} style={head.rowStyle}>
                                  <Button variant="outlined" color="primary" onClick={() => handleOpenDiscountDialog(row)}>
                                    Descontos
                                  </Button>
                                  {/* <DiscountDialog
                                    open={openDiscountDialog}
                                    close={handleCloseDiscountDialog}
                                    onActiveDiscountChange={handleActiveDiscountChange}
                                  /> */}
                                </TableCell>
                              )
                              :
                              head.type === 'score' ?
                                <TableCell
                                  key={head.id + head.label + index}
                                  align={head.align ?? 'center'}
                                  sx={{ ...head.rowStyle }}
                                >
                                  <CircleIcon sx={{
                                    color: `${cellValue === 0 ? "#C00000" : cellValue === 1 ? "#FFC000" : cellValue === 2 ? "#00B050" : "var(--bs-border-color)"}`,
                                    fontSize: {
                                      xs: "clamp(1.75rem, 10vw, 2.5rem)",
                                      sm: "2.5rem",
                                      md: "clamp(1.75rem, 3.1vw, 2.5rem)"
                                    }
                                  }} />
                                </TableCell>
                                :
                                <TableCell
                                  key={head.id + head.label + index}
                                  align={head.align ?? 'center'}
                                  sx={{ ...head.rowStyle, ...(head.type === 'numeric' || head.type === 'monetary' ? { textWrap: "nowrap" } : {}) }}
                                >
                                  {cellValue}
                                </TableCell>
                        )
                      })}

                    <TableCell>
                      <Tooltip title="Remover Produto">
                        <IconButton
                          aria-label="delete"
                          onClick={(_) => {
                            //if (!row.analytics) {
                            actions.removeRow(section.id, row.id);
                            // }
                            // else {
                            //     handleClickOpenDialog(section.id, row)
                            // }
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </ThemeProvider>
      </TableContainer>
    </>
  );
}