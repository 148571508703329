import { Grid } from "@mui/material";
import { GetSafeNumber, isDefaultDate, isNullOrEmpty } from "../../../../../../helpers";

export default function Resume({ cenario, metodoPagamento }) {
  return <>
    <Grid item xs={2} sm={8} md={2.4}>
      <label className="d-block">Campanha</label>
      <h5>{cenario?.campanha}</h5>
    </Grid>
    <Grid item xs={2} sm={8} md={2.4}>
      <label className="d-block">Condição</label>
      <h5>{cenario?.condicao}</h5>
    </Grid>
    <Grid item xs={2} sm={8} md={2.4}>
      <label className="d-block">Vencimento</label>
      <h5>{!isDefaultDate(cenario?.vencimento) && new Date(cenario?.vencimento).toLocaleDateString("pt-BR")}</h5>
    </Grid>
    <Grid item xs={2} sm={8} md={2.4}>
      <label className="d-block">Método de Pagamento</label>
      <h5>{metodoPagamento?.metodo}</h5>
    </Grid>
    <Grid item xs={2} sm={8} md={2.4}>
      {metodoPagamento?.isBarter &&
        <>
          <label className="d-block">Valorização Barter</label>
          <h5>{isNullOrEmpty(cenario?.valorizacaoBarter) ? '-' : `${GetSafeNumber(cenario?.valorizacaoBarter, 2).toLocaleString("pt-BR")}%`}</h5>
        </>
      }

      {metodoPagamento?.isBonusAdimplencia &&
        <>
          <label className="d-block">Bônus Adimplencia</label>
          <h5>{isNullOrEmpty(cenario?.bonusAdimplencia) ? '-' : `${GetSafeNumber(cenario?.bonusAdimplencia, 2).toLocaleString("pt-BR")}%`}</h5>
        </>
      }
    </Grid>
  </>
}