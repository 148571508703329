import React from "react";
import TableCell from '@mui/material/TableCell';
import { createTheme } from '@mui/material/styles';
import { Tooltip } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import MuiAlert from '@mui/material/Alert';
import { useWatch } from "react-hook-form";
import { Controller } from "react-hook-form";
import { GetSafeNumber } from "../../../../../helpers";

export const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
          minWidth: "77px",
          padding: "6px 6px",
          "&:first-of-type": {
            padding: "6px 6px 6px 16px",
          },
          "&:last-child": {
            padding: "6px 16px 6px 6px",
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    }
  }
});

export const themeCollapse = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
          minWidth: "77px",
          padding: "6px 16px",
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    },
    MuiInputBase: {
      styleOverrides: {
        // Name of the slot
        root: {
          fontSize: "0.775rem",
        },
      }
    }
  }
});

export const zeroPad = (num, places) => String(num).padStart(places, '0')

export const invertSignal = (value = 0) => value * -1;

export const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const HtmlTooltip = styled(({ className, ...props }) => (<Tooltip {...props} classes={{ popper: className }} />))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    // maxWidth: 220,
    border: 'none',
  },
}));

export const HtmlTooltipTable = styled(({ className, ...props }) => (<Tooltip {...props} classes={{ popper: className }} />))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    maxWidth: "100vw",
    border: 'none',
  },
}));

export function CellInputAlert({ messageError, maxValue, align = "center", control, name = "", customOnChange = (event) => { }, ...props }) {
  // const { messageError, maxValue } = props;
  const [open, setOpen] = React.useState(false);

  const isAllowed = (value, max) => {
    var number = Number(value.value)

    if (number > max) {
      setOpen(true);
    } else {
      setOpen(false);
    }

    return number <= max;
  };

  const handleClose = () => {

    setOpen(false);
  };

  return (<HtmlTooltip placement="right" leaveDelay={600} open={open} onClose={handleClose}
    title={
      <React.Fragment>
        <Alert severity="error">
          {messageError}
        </Alert>
      </React.Fragment>
    }
  >
    <TableCell align={align}>
      <Controller
        render={({ field }) => {
          const { onChange, ...other } = field;
          return (<NumericFormatFoward
            onValueChange={(values, sourceInfo) => { onChange({ target: { name: sourceInfo.event.target.name, value: Number(values.value) } }); customOnChange({ target: { name: sourceInfo.event.target.name, value: Number(values.value) } }) }}
            {...other}
            {...props}
            isAllowed={(values, sourceInfo) => isAllowed(values, maxValue)}
          />)
        }
        }
        name={name}
        control={control}
      />
    </TableCell>
  </HtmlTooltip>
  );
};

const NumericFormatFoward = React.forwardRef((props, ref) => {
  return <NumericFormat {...props} />
})


export function WatchMaxInput({ names = [], placeholder = (max) => `${max}`, control, defaultMaxValue = 0, decimalScale = 0, ...props }) {
  const totalMax = useWatch({
    control,
    name: names
  });

  var max = totalMax.reduce((total, x) => { return total + GetSafeNumber(x) }, 0)
  if (!isFinite(max) || isNaN(max)) max = 0;
  max = Number((defaultMaxValue - max).toFixed(decimalScale));
  if (!isFinite(max) || isNaN(max)) max = 0;

  return (
    <React.Fragment>
      <CellInputAlert
        {...props}
        decimalScale={decimalScale}
        placeholder={placeholder(max)}
        maxValue={max}
        control={control}
      />
    </React.Fragment>
  );
}

function GroupByParentName(values = [], Names = []) {
  var val = [];
  if (Names.length > 0) {
    var namesProps = Names.map(item => {
      var aux = item.split('.')
      aux.pop();
      return aux.join('.')
    }).filter((value, index, self) => self.indexOf(value) === index);
    namesProps.forEach(name => {
      var aux = [];
      var indexs = Names.map((x, xi) => { if (x.includes(name)) return xi; return undefined; }).filter(x => x !== undefined);
      aux.push(name);
      indexs.forEach(i => {
        aux.push(values[i]);
      });
      val.push(aux);
    });
  }
  return val
}

export function WatchNodeIsolate({ control, valueToWatch, calcValue = (values) => { return values }, textDisplay = (value) => `${value}`, Node = TableCell, groupByParentName = false, groupByFunc = GroupByParentName, ...props }) {
  const values = useWatch({
    control,
    name: valueToWatch,
  });

  let value = calcValue(values);

  if (groupByParentName) {
    value = groupByFunc(value, valueToWatch);
  }

  return (
    <Node {...props}>
      {textDisplay(value)}
    </Node>
  );
}
