import { createBrowserHistory } from "history";
import { useNavigate } from "react-router-dom";

export const history = createBrowserHistory();

export function classUseRedirect(Component) {
    return (props) => {
      const redirect = useNavigate();
      const navigateProps = { redirect }
      return <Component {...props} {...navigateProps} />
    }
  }