import { GetSafeNumber, isDefaultDate, isNullOrEmpty } from "../../../../../../helpers"
import { formatCpfCnpj, formatDate } from "../../../../../../helpers/utils";

const DataTable = ({ title, isSeeds, currency, cenario, formulario, metodoPagamento, situacaoEspecial }) => {
    const itens = isSeeds ? cenario?.rows : cenario?.itensCenarios;

    return (
        <>
            <div style={{ marginTop: "16px", fontSize: "13px", color: "white", backgroundColor: "#0072ce", borderRadius: "8px", padding: "1rem" }}>
                <b>{title}</b>
            </div>

            <div style={{ marginTop: "16px", display: 'grid', gap: '16px', gridTemplateColumns: 'repeat(12, 1fr)', alignItems: 'center' }}>
                <div style={{ gridColumn: 'span 3' }}>
                    <h3 style={{ display: 'block', fontWeight: 'bold', margin: 0 }}>Campanha</h3>
                    <h3 style={{ fontWeight: '400', margin: 0 }}>{isSeeds ? cenario?.campanhaGroup : cenario?.campanha}</h3>
                </div>

                <div style={isSeeds ? { gridColumn: 'span 5' } : { gridColumn: 'span 1.2' }}>
                    <h3 style={{ display: 'block', fontWeight: 'bold', margin: 0 }}>Condição</h3>
                    <h3 style={{ fontWeight: '400', margin: 0 }}>{isSeeds ? metodoPagamento?.metodo : cenario?.condicao}</h3>
                </div>

                <div style={{ gridColumn: 'span 2.4' }}>
                    <h3 style={{ display: 'block', fontWeight: 'bold', margin: 0 }}>Vencimento</h3>
                    <h3 style={{ fontWeight: '400', margin: 0 }}>{!isDefaultDate(cenario?.vencimento) && new Date(cenario?.vencimento).toLocaleDateString("pt-BR")}</h3>
                </div>

                {!isSeeds &&
                    <div style={{ gridColumn: 'span 3' }}>
                        <h3 style={{ display: 'block', fontWeight: 'bold', margin: 0 }}>Método de Pagamento</h3>
                        <h3 style={{ fontWeight: '400', margin: 0 }}>{metodoPagamento?.metodo}</h3>
                    </div>
                }

                {metodoPagamento?.isBarter && (
                    <div style={{ gridColumn: 'span 2.4' }}>
                        <h3 style={{ display: 'block', fontWeight: 'bold', margin: 0 }}>Valorização Barter</h3>
                        <h3 style={{ fontWeight: '400', margin: 0 }}>{isNullOrEmpty(cenario?.valorizacaoBarter) ? '-' : `${GetSafeNumber(cenario?.valorizacaoBarter, 2).toLocaleString("pt-BR")}%`}</h3>
                    </div>
                )}

                {metodoPagamento?.isBonusAdimplencia && (
                    <div style={{ gridColumn: 'span 2.4' }}>
                        <h3 style={{ display: 'block', fontWeight: 'bold', margin: 0 }}>Bônus de Adimplência</h3>
                        <h3 style={{ fontWeight: '400', margin: 0 }}>{isNullOrEmpty(cenario?.bonusAdimplencia) ? '-' : `${GetSafeNumber(cenario?.bonusAdimplencia, 2).toLocaleString("pt-BR")}%`}</h3>
                    </div>
                )}
            </div>

            <div style={{ marginTop: "16px", display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)', gap: '16px', alignItems: 'center' }}>
                <div style={{ gridColumn: 'span 3' }}>
                    <h3 style={{ margin: 0 }}><b>Order Type:</b></h3>
                    <h4 style={{ fontWeight: '400', margin: 0 }}>{situacaoEspecial.find(x => x.value === formulario?.orderType)?.name ?? 'N/A'}</h4>
                </div>

                <div style={{ gridColumn: 'span 3' }}>
                    <h3 style={{ margin: 0 }}><b>PO Number:</b></h3>
                    <h4 style={{ fontWeight: '400', margin: 0 }}>{formulario.poNumber ?? 'N/A'}</h4>
                </div>

                <div style={{ gridColumn: 'span 12' }}>
                    <h3 style={{ margin: 0 }}><b>Instruções CSR:</b></h3>
                    <h4 style={{ fontWeight: '400', margin: 0 }}>{formulario.instrucoesCsr ?? 'N/A'}</h4>
                </div>
            </div>

            <div style={{ marginTop: "16px", color: "white", backgroundColor: "#0072ce", fontWeight: "bold", borderRadius: "8px", padding: "1rem" }}>
                <p style={{ margin: 0 }}>Produtos do cenário</p>
            </div>

            <div style={{ marginTop: "16px", overflowX: 'auto', border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#fff' }}>
                <table style={{ minWidth: '650px', width: '100%', borderCollapse: 'collapse', fontSize: 'small' }} aria-label="custom pagination table">
                    <thead>
                        <tr style={{ fontWeight: 'bold', textAlign: 'left', whiteSpace: 'nowrap', fontSize: "12px" }}>
                            <th style={{ padding: '8px', borderBottom: '1px solid #ddd', fontSize: "12px" }}>Produto</th>
                            {isSeeds ? <th style={{ padding: '8px', borderBottom: '1px solid #ddd', fontSize: "12px" }}>Sufixo</th> : ''}
                            <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'right', fontSize: "12px" }}>Volume</th>
                            <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'right', fontSize: "12px" }}>Preço Negociado</th>
                            <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'right', fontSize: "12px" }}>Valor Final</th>
                        </tr>
                    </thead>
                    <tbody>
                        {itens?.map((row, index) => (
                            <tr id={"produtoRow" + index} style={{ borderBottom: '1px solid #ddd' }}>
                                <td style={{ padding: '8px' }}>{row?.produto}</td>
                                {isSeeds &&
                                    <td style={{ padding: '8px' }}>
                                        {row?.sufixo}
                                    </td>
                                }
                                <td style={{ padding: '8px', textAlign: 'right' }}>{GetSafeNumber(row?.volume).toLocaleString("pt-BR")}</td>
                                <td style={{ padding: '8px', textAlign: 'right' }}>
                                    {GetSafeNumber(row?.precoNegociado, 2).toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: currency === "BRL" ? "BRL" : "USD",
                                    })}
                                </td>
                                <td style={{ padding: '8px', textAlign: 'right' }}>
                                    {GetSafeNumber(isSeeds ? row?.valorFinalNegociado : row?.valorFinal).toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: currency === "BRL" ? "BRL" : "USD",
                                    })}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div style={{ marginTop: "16px", overflow: 'auto', border: '1px solid #ccc', borderRadius: '4px', backgroundColor: '#fff' }}>
                <table aria-label="collapsible table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr style={{
                            backgroundColor: '#0072ce',
                            color: '#fff'
                        }}>
                            <th style={{ padding: '8px 16px', fontWeight: 'bold', fontSize: "12px", textAlign: "left" }}>Cliente</th>
                            <th style={{ padding: '8px 16px', fontWeight: 'bold', fontSize: "12px", textAlign: "left" }}>Município/UF</th>
                            <th style={{ padding: '8px 16px', fontWeight: 'bold', fontSize: "12px", textAlign: "left" }}>CPF/CNPJ</th>
                            <th style={{ padding: '8px 16px', fontWeight: 'bold', fontSize: "12px", textAlign: "left" }}>Instruções de entrega</th>
                            <th style={{ padding: '8px 16px', fontWeight: 'bold', textAlign: 'right', fontSize: "12px" }}>Percentual Volume %</th>
                            <th style={{ padding: '8px 16px', fontWeight: 'bold', textAlign: 'right', fontSize: "12px" }}>Valor Final</th>
                        </tr>
                    </thead>
                    <tbody>
                        {formulario?.cenarioFormularioAccount?.map((row, i) => {
                            const CalcTotal = (values = []) => {
                                return values.reduce((total, item) => {
                                    var row = itens.find(x => x.id === item.itensCenarioId)
                                    var valor = item.volumeReal * row?.precoNegociado
                                    return total = total + valor
                                }, 0)
                            }

                            const formularioAccount = formulario?.cenarioFormularioAccount[i];

                            return (
                                <>
                                    <tr id={"clienteRow" + i} style={{ borderBottom: 'unset' }}>
                                        <td style={{ padding: '8px 16px', fontSize: "12px" }}>{row.shipTo?.customerNumber} - {row.account?.cliente}</td>
                                        <td style={{ padding: '8px 16px', fontSize: "12px" }}>{row.account?.municipio}/{row.account?.uf}</td>
                                        <td style={{ padding: '8px 16px', fontSize: "12px" }}>{formatCpfCnpj(row.account?.cpfCnpjDoCliente)}</td>
                                        <td style={{ padding: '8px 16px', fontSize: "12px" }}>
                                            {formularioAccount?.instrucaoEntrega}
                                        </td>
                                        <td style={{ textAlign: 'right', padding: '8px 16px', fontSize: "12px" }}>
                                            {formularioAccount?.percentual}
                                        </td>
                                        <td style={{ textAlign: 'right', padding: '8px 16px', fontSize: "12px" }}>
                                            {
                                                CalcTotal(formularioAccount?.cenarioFormularioAccountItens).toLocaleString("pt-BR", {
                                                    style: "currency",
                                                    currency: (currency === "BRL" ? "BRL" : "USD"),
                                                })
                                            }
                                        </td>
                                    </tr>

                                    <tr id={"produtosClienteRow" + i}>
                                        <td colSpan={6} style={{ padding: 0 }}>
                                            <div style={{ margin: '8px' }}>
                                                <table style={{ minWidth: '650px', width: '100%', borderCollapse: 'collapse' }} aria-label="custom pagination table">
                                                    <thead>
                                                        <tr style={{ backgroundColor: '#0072ce', color: '#fff' }}>
                                                            <th style={{ padding: '8px 16px', whiteSpace: 'nowrap', fontWeight: 'bold', color: '#fff', fontSize: "12px", textAlign: "left" }}>
                                                                Produto
                                                            </th>
                                                            {!isSeeds ?
                                                                <th style={{ padding: '8px 16px', whiteSpace: 'nowrap', fontWeight: 'bold', color: '#fff', fontSize: "12px", textAlign: "left" }}>
                                                                    Material
                                                                </th>
                                                                :
                                                                <th style={{ padding: '8px 16px', whiteSpace: 'nowrap', fontWeight: 'bold', color: '#fff', fontSize: "12px", textAlign: "left" }}>
                                                                    Sufixo
                                                                </th>
                                                            }
                                                            <th style={{ padding: '8px 16px', whiteSpace: 'nowrap', fontWeight: 'bold', color: '#fff', fontSize: "12px", textAlign: "left" }}>
                                                                Volume
                                                            </th>
                                                            {!isSeeds && <>
                                                                <th style={{ padding: '8px 16px', whiteSpace: 'nowrap', fontWeight: 'bold', color: '#fff', fontSize: "12px", textAlign: "left" }}>
                                                                    Embalagem (cx/sc)
                                                                </th>
                                                                <th style={{ padding: '8px 16px', whiteSpace: 'nowrap', fontWeight: 'bold', color: '#fff', fontSize: "12px", textAlign: "left" }}>
                                                                    Volume Real
                                                                </th>
                                                            </>
                                                            }
                                                            <th style={{ padding: '8px 16px', whiteSpace: 'nowrap', fontWeight: 'bold', color: '#fff', fontSize: "12px", textAlign: "center" }}>
                                                                Data de Entrega
                                                            </th>
                                                            <th style={{ padding: '8px 16px', whiteSpace: 'nowrap', fontWeight: 'bold', color: '#fff', textAlign: 'right', fontSize: "12px" }}>
                                                                Preço Negociado
                                                            </th>
                                                            <th style={{ padding: '8px 16px', whiteSpace: 'nowrap', fontWeight: 'bold', color: '#fff', textAlign: 'right', fontSize: "12px" }}>
                                                                Valor Final
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {formularioAccount?.cenarioFormularioAccountItens?.map((item) => {
                                                            const row = itens.find(x => x.id === item.itensCenarioId);

                                                            return (
                                                                <tr id={"produtoCliRow" + item.itensCenarioId}>
                                                                    <td style={{ padding: '8px 16px', fontSize: "12px" }}>
                                                                        {row?.produto}
                                                                    </td>
                                                                    <td style={{ padding: '8px 16px', textAlign: "left", fontSize: "12px" }}>
                                                                        {!isSeeds ?
                                                                            (item.materiais?.find(m => m.materialId == item.material)?.materialName ?? "")
                                                                            :
                                                                            (item.materiais?.find(m => m.id == item.material)?.sufixo ?? "")
                                                                        }
                                                                    </td>
                                                                    <td style={{ textAlign: 'left', padding: "8px 16px", fontSize: "12px" }}>
                                                                        {item.volume}
                                                                    </td>
                                                                    {!isSeeds && (
                                                                        <>
                                                                            <td style={{ padding: '8px 16px', fontSize: "12px", textAlign: 'left' }}>
                                                                                {item.qtdPedido}
                                                                            </td>

                                                                            <td style={{ padding: '8px 16px', fontSize: "12px", textAlign: 'left' }}>
                                                                                {GetSafeNumber(item.volumeReal, 2).toLocaleString("pt-BR")}
                                                                            </td>
                                                                        </>
                                                                    )}
                                                                    <td style={{ textAlign: 'center', padding: "8px 16px", fontSize: "12px" }}>
                                                                        {formatDate(item.dataEntregaSol)}
                                                                    </td>
                                                                    <td style={{ textAlign: 'right', padding: "8px 16px", fontSize: "12px" }}>
                                                                        {row?.precoNegociado?.toLocaleString("pt-BR", {
                                                                            style: "currency",
                                                                            currency: (currency === "BRL" ? "BRL" : "USD"),
                                                                        })}
                                                                    </td>
                                                                    <td style={{ textAlign: 'right', padding: "8px 16px", fontSize: "12px" }}>
                                                                        {isSeeds ?
                                                                            (item.volume * row?.precoNegociado)?.toLocaleString("pt-BR", {
                                                                                style: "currency",
                                                                                currency: currency === "BRL" ? "BRL" : "USD",
                                                                            }) :
                                                                            (item.volumeReal * row?.precoNegociado)?.toLocaleString("pt-BR", {
                                                                                style: "currency",
                                                                                currency: currency === "BRL" ? "BRL" : "USD",
                                                                            })}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default DataTable;